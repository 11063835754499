var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"validation-result ava-validation-result",class:{ 'is-valid': _vm.value.isValid, 'is-invalid': !_vm.value.isValid }},[_c('CCardHeader',[_c('div',[_c('CIcon',{class:_vm.iconClasses,attrs:{"name":_vm.iconName,"size":"xl"}})],1),_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.displayLabel))]),(_vm.isError)?_c('div',{staticClass:"text-center text-small text-muted"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]),(!_vm.isError)?_c('CCardBody',{staticClass:"d-flex text-center"},[_c('div',{staticClass:"validation-value-wrapper"},[_c('div',{staticClass:"labeled-value-value expected-value"},[_vm._v(" "+_vm._s(_vm.expectedValue)+" ")]),_c('label',{staticClass:"text-muted text-small labeled-value-label"},[_vm._v("Expected")])]),_c('div',{staticClass:"validation-value-wrapper"},[_c('div',{staticClass:"labeled-value-value actual-value",class:{
          'text-danger': !_vm.value.isValid,
          'text-warning': _vm.value.isValid && !_vm.value.isChecked,
        }},[_vm._v(" "+_vm._s(_vm.actualValue)+" ")]),_c('label',{staticClass:"text-small labeled-value-label",class:{
          'text-warning': _vm.value.isValid && !_vm.value.isChecked,
          'text-danger': !_vm.value.isValid,
          'text-muted': _vm.value.isValid,
        }},[_vm._v("Actual")])])]):_c('CCardBody',[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.errorDescription))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }