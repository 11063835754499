<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol col="1">
          <MRecordStatusBadge
            class="h2"
            :value="lineStatus"
            style="margin-top: auto; margin-bottom: auto"
          />
        </CCol>
        <CCol>
          <div class="w-100 d-flex">
            <LabeledValue label="Item Code">
              <span class="h4">{{ line.itemCode || '--' }}</span>
            </LabeledValue>
            <LabeledValue label="Quantity">
              <span class="h4">{{
                $format.number(line.quantity, 0) || '--'
              }}</span>
            </LabeledValue>
            <LabeledValue label="Amount">
              <span class="h4">{{
                $format.currency(currencyCode, line.amount) || '--'
              }}</span>
            </LabeledValue>
          </div>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow class="pb-4">
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Taxable Amount</small><br />
            <strong class="h4">{{
              $format.currency(currencyCode, line.taxableAmount) || '--'
            }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Total Tax Amount</small><br />
            <strong class="h4">{{
              $format.currency(currencyCode, line.totalTaxAmount) || '--'
            }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Tax Rate</small><br />
            <strong class="h4"
              >{{ line.taxRate ? $format.percent(line.taxRate, 4) : '--' }}
            </strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Eff. Tax Rate</small><br />
            <strong class="h4">{{
              line.effectiveTaxRate
                ? $format.percent(line.effectiveTaxRate, 4)
                : '--'
            }}</strong>
          </CCallout>
        </CCol>
      </CRow>
      <CRow v-if="validationResults.length > 0">
        <CCol>
          <h4>Line Level Validations</h4>
        </CCol>
      </CRow>

      <CRow v-if="validationResults.length > 0" class="pb-4">
        <CCol
          v-for="(v, vIdx) in line.validationResults"
          :key="`${vIdx}_${v.name}`"
          col="6"
        >
          <AvaValidationResult :value="v" />
        </CCol>
      </CRow>
      <CRow v-if="lineTaxes.length > 0">
        <CCol>
          <div class="w-100 d-flex">
            <div>
              <span class="h4">
                Line Level Taxes
                <span class="text-muted">({{ lineTaxes.length }})</span>
              </span>
            </div>
            <MCollapseToggler :value.sync="showTaxDetails" />
          </div>
          <hr />
        </CCol>
      </CRow>
      <CCollapse v-if="lineTaxes.length > 0" :show="showTaxDetails">
        <CRow
          v-for="(lineTax, tIdx) in lineTaxes"
          :key="`${tIdx}_${lineTax.jurisdictionType}_${lineTax.jurisdictionCode}_${lineTax.liabilityType}_${lineTax.taxSubTypeId}`"
        >
          <CCol>
            <CCard class="line-tax-details-card">
              <CCardHeader>
                <CRow class="pb-2 tax-title-row">
                  <CCol>
                    <div class="w-100 d-flex">
                      <div class="h5">
                        <span class="pr-1"
                          ><strong>{{ tIdx + 1 }}.</strong></span
                        >
                        <strong
                          >{{ lineTax.taxType }}: {{ lineTax.taxName }}</strong
                        >
                      </div>
                      <div>
                        <CBadge
                          :color="
                            lineTax.liabilityType === 'Seller'
                              ? 'info'
                              : lineTax.liabilityType === 'Buyer'
                              ? 'primary'
                              : 'secondary'
                          "
                          class="ml-3"
                          >{{ lineTax.liabilityType }}</CBadge
                        >
                        <CBadge
                          v-show="lineTax.isFee"
                          color="success"
                          class="ml-1"
                          >Fee</CBadge
                        >
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CRow>
                      <CCol>
                        <fieldset>
                          <legend>Tax Authority</legend>
                          <div class="d-flex w-100">
                            <LabeledValue
                              label="Liability Type"
                              :value="lineTax.liabilityType"
                            />
                            <LabeledValue
                              label="Sourcing"
                              :value="lineTax.sourcing"
                            />
                            <LabeledValue
                              label="Tax Type"
                              :value="lineTax.taxType"
                            />
                            <LabeledValue
                              label="Jurisdiction Type"
                              :value="lineTax.jurisdictionType"
                            />
                            <LabeledValue
                              label="Tax Name"
                              :value="lineTax.taxName"
                            />
                            <LabeledValue v-show="lineTax.isFee" label="Fee">
                              <MCheckIcon :value="lineTax.isFee" />
                            </LabeledValue>
                          </div>
                        </fieldset>
                      </CCol>
                    </CRow>
                    <CRow class="pb-2">
                      <CCol>
                        <fieldset>
                          <div class="d-flex w-100">
                            <LabeledValue
                              label="Country"
                              :value="lineTax.country || '--'"
                            />
                            <LabeledValue
                              label="Region"
                              :value="lineTax.region || '--'"
                            />
                            <LabeledValue
                              v-if="lineTax.jurisdictionCode"
                              label="Jurisdiction Code"
                              :value="lineTax.jurisdictionCode || '--'"
                            />
                            <LabeledValue
                              v-if="!!lineTax.stateFIPS"
                              label="State FIPS"
                              :value="lineTax.stateFIPS"
                            />
                            <LabeledValue
                              v-if="!!lineTax.countyFIPS"
                              label="County FIPS"
                              :value="lineTax.countyFIPS"
                            />
                            <LabeledValue
                              v-if="lineTax.inState"
                              label="In State"
                            >
                              <MCheckIcon :value="lineTax.inState" />
                            </LabeledValue>
                          </div>
                        </fieldset>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol>
                    <fieldset>
                      <legend>Tax Properties</legend>
                      <CRow v-if="hasTaxRuleIdProperties(lineTax)">
                        <CCol>
                          <div class="d-flex w-100">
                            <LabeledValue
                              label="Non-Taxable Type"
                              :value="lineTax.nonTaxableType || '--'"
                            />
                            <LabeledValue
                              label="Tax Rate Rule ID"
                              :value="lineTax.rateRuleId || '--'"
                            />
                            <LabeledValue
                              label="Tax Type Group ID"
                              :value="lineTax.taxTypeGroupId || '--'"
                            />
                            <LabeledValue
                              label="Signature Code"
                              :value="lineTax.signatureCode || '--'"
                            />
                            <LabeledValue
                              label="SER Code"
                              :value="lineTax.serCode || '--'"
                            />
                            <LabeledValue
                              label="State Assigned No."
                              :value="lineTax.stateAssignedNo || '--'"
                            />
                          </div>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <div class="d-flex w-100">
                            <LabeledValue
                              label="Tax"
                              :value="lineTax.tax || '--'"
                            />
                            <LabeledValue
                              label="Tax Rate"
                              :value="$format.percent(lineTax.rate, 4) || '--'"
                            />
                            <LabeledValue
                              label="Taxable Amount"
                              :value="lineTax.taxableAmount || '--'"
                            />
                            <LabeledValue
                              label="Non-Taxable Amount"
                              :value="lineTax.nonTaxableAmount || '--'"
                            />
                            <LabeledValue
                              label="Rate Type"
                              :value="lineTax.rateType || '--'"
                            />
                            <LabeledValue
                              label="Rate Type Code"
                              :value="lineTax.rateTypeCode || '--'"
                            />
                          </div>
                        </CCol>
                      </CRow>
                    </fieldset>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCollapse>

      <CRow v-if="hasAnyLineInputProperties" class="pt-4">
        <CCol>
          <div class="w-100 d-flex">
            <span class="h4">Line Input Properties</span>
            <MCollapseToggler :value.sync="showLineInput" />
          </div>
          <hr />
        </CCol>
      </CRow>
      <CCollapse v-if="hasAnyLineInputProperties" :show="showLineInput">
        <CRow v-if="hasUserElements" class="pb-4">
          <CCol>
            <fieldset>
              <legend>User Defined Fields</legend>
              <div class="user-element-container">
                <LabeledValue
                  v-for="e in userElements"
                  :key="e.key"
                  :label="e.label"
                  :value="e.value"
                />
              </div>
            </fieldset>
          </CCol>
        </CRow>
        <CRow v-if="hasAddresses" class="pb-4">
          <CCol>
            <fieldset>
              <legend>Addresses</legend>
              <CRow class="pt-2">
                <CCol
                  v-for="(address, addressIndex) in lineAddresses"
                  :key="`${line.lineNumber}_${addressIndex}_${address.name}`"
                  col="6"
                  md="4"
                >
                  <DocumentAddress :value="address" :label="address.label" />
                </CCol>
              </CRow>
            </fieldset>
          </CCol>
        </CRow>
        <CRow v-if="hasLineAttributes" class="pb-4">
          <CCol>
            <fieldset>
              <legend>Line Attributes</legend>
              <div class="user-element-container">
                <LabeledValue
                  v-for="e in lineAttributes"
                  :key="e.key"
                  :label="e.label"
                >
                  <MCheckIcon
                    v-if="e.fieldType === 'BOOLEAN'"
                    :value="e.value"
                    show-false
                  />
                  <span v-else>
                    {{ e.value }}
                  </span>
                </LabeledValue>
              </div>
            </fieldset>
          </CCol>
        </CRow>
      </CCollapse>
    </CCardBody>
  </CCard>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
import AvaValidationResult from '../AvaValidationResult';
import MRecordStatusBadge from '@/components/MRecordStatusBadge';
import {
  isIgnoredField,
  getFieldForProperty,
} from '@/utils/ava/avaFieldUtils.js';

import { titleCase } from '@/utils/helpers';
export default {
  name: 'AvaDocumentLineResultTab',
  components: { DocumentAddress, AvaValidationResult, MRecordStatusBadge },
  props: {
    line: {
      type: Object,
      required: true,
    },
    currencyCode: {
      type: String,
      required: false,
      default: 'USD',
    },
    docStatus: {
      type: String,
      required: false,
      default: 'ERROR',
    },
  },
  data() {
    return {
      showLineInput: false,
      showTaxDetails: false,
      lineAddresses: [],
      lineAttributes: [],
      userElements: [],
      lineStatus: 'VALID',
      lineTaxes: [],
      lineTaxableAmount: null,
      validationResults: [],
    };
  },
  computed: {
    hasUserElements() {
      return this.userElements.length > 0;
    },
    hasAddresses() {
      return this.lineAddresses.length > 0;
    },
    hasLineAttributes() {
      return this.lineAttributes.length > 0;
    },
    hasAnyLineInputProperties() {
      return (
        this.hasUserElements || this.hasAddresses || this.hasLineAttributes
      );
    },
  },
  mounted() {
    if (this.docStatus === 'FAILED') {
      this.lineStatus = 'FAILED';
    } else {
      this.lineStatus = this.line.lineStatus;
    }

    this.validationResults = (this.line.validationResults || []).sort(
      (a, b) => {
        return ('' + a.name).localeCompare(b.name);
      },
    );

    this.lineAddresses = Object.keys(this.line.addresses || {})
      .map((key) => {
        let address = this.line.addresses[key];
        const group = address.group.replace('LINE.', '');
        return Object.freeze({
          ...address,
          group,
          label: this.$t(`addressType.${group}`),
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.lineTaxes = (this.line.taxes || []).map((t) => {
      const v = {
        ...t,
        taxableAmount: this.$format.currency(
          this.currencyCode,
          t.taxableAmount,
        ),
        nonTaxableAmount: this.$format.currency(
          this.currencyCode,
          t.nonTaxableAmount,
        ),
        exemptAmount: this.$format.currency(this.currencyCode, t.exemptAmount),
        tax: this.$format.currency(this.currencyCode, t.tax),
      };

      return Object.freeze(v);
    });

    this.userElements = Object.keys(this.line.userDefinedFields || {})
      .map((field) => {
        return Object.freeze({
          label: field,
          value: this.line.userDefinedFields[field],
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.lineAttributes = Object.keys(this.line.lineProperties)
      .reduce((acc, key) => {
        const field = getFieldForProperty(key);
        if (!!field && !isIgnoredField(key)) {
          acc.push(
            Object.freeze({
              label: titleCase(field.label),
              value: this.line.lineProperties[key],
            }),
          );
        }

        return acc;
      }, [])
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });
  },
  methods: {
    hasTaxRuleIdProperties(tax) {
      return !!(
        tax.rateRuleId ||
        tax.taxTypeGroupId ||
        tax.signatureCode ||
        tax.serCode ||
        tax.stateAssignedNo ||
        tax.nonTaxableType
      );
    },
  },
};
</script>
