import svgMap from 'svgmap';
import Address from '@/models/Address';
import { formatDate } from '@/utils/format';

import {
  SBX_FORMAT_FIELDS,
  // SBX_ADDRESS_FIELD_NAMES,
  // SBX_LINE_FIELDS,
  // SBX_REWRITE_FIELDS,
  parseCustomAttributes,
  SBX_INVOICE_FIELDS_MAP,
} from '@/utils/sbxFieldUtils';

export function reduceJson(map) {
  if (!map) {
    return new Map();
  }

  let reduced = new Map();
  Object.keys(map).forEach((key) => {
    if (map[key]) {
      if (typeof map[key] === 'object') {
        let mapped = reduceJson(map[key]);
        if (!!mapped && mapped.size > 0) {
          reduced.set(key, Object.fromEntries(mapped));
        }
      } else {
        reduced.set(key, map[key]);
      }
    }
  });

  return reduced;
}

export function createSvgMap(countries) {
  return new svgMap({
    targetElementID: 'test-deck-map',
    colorMax: '#0D7930',
    colorMin: '#D6FAE2',
    noDataText: 'No relevant test data',
    flagURL: '/img/countries/svg/{0}.svg',
    data: {
      applyData: 'shipTo',

      data: {
        shipTo: {
          name: 'Ship To',
        },
        shipFrom: {
          name: 'Ship From',
        },
        total: {
          name: 'Total',
        },
      },
      values: countries,
    },
  });
}

export function getSortColumnName(columnName) {
  if (columnName.startsWith('invoice_ship_')) {
    return columnName.split('invoice_').pop();
  }

  return columnName.split('invoice.').pop();
}

function fmtMoney(amount, currencyCode) {
  const num = Number.parseFloat(`${amount}`);
  if (isNaN(num)) {
    return '--';
  }

  const locale = navigator.language || navigator.userLanguage || 'en-US';
  if (currencyCode) {
    return Intl.NumberFormat([locale], {
      style: 'currency',
      currency: currencyCode.toUpperCase(),
      minimumFractionDigits: 2,
    }).format(num);
  } else {
    return Intl.NumberFormat([locale], {
      minimumFractionDigits: 2,
    }).format(num);
  }
}
export function formatCurrency(amount, invoice, line) {
  const num = Number.parseFloat(`${amount}`);
  if (isNaN(num)) {
    return '--';
  }

  let currency = invoice['INVOICE.CURRENCY_CODE'];
  if (line['LINE.CURRENCY_CODE']) {
    currency = line['LINE.CURRENCY_CODE'];
  }

  return fmtMoney(num, currency);
}

// export function mapInvoiceAddresses(trxId, trxProps) {
//   return Object.keys(SBX_ADDRESS_FIELD_NAMES).reduce((prv, key) => {
//     const prop = `INVOICE.${key}`;
//     if (trxProps[prop]) {
//       const addrMap = trxProps[prop];
//       const address = Address.fromOneSource(addrMap, `${trxId}_${key}`, prop);
//       prv.push(address);
//     }
//     return prv;
//   }, []);
// }

function flattenReg(value) {
  if (!value) {
    return null;
  }

  return (value || []).join(', ');
}

export function mapTransaction(
  transaction,
  formatMessage = (i18nKey, value) => `${i18nKey}.${value}`,
) {
  const activeLineFields = new Set(transaction.activeFields || []);
  const currencyCode = transaction.currencyCode || 'USD';

  const taxSummary = {
    taxableBasis: fmtMoney(
      transaction.taxSummary?.nonTaxableBasis,
      currencyCode,
    ),
    nonTaxableBasis: fmtMoney(
      transaction.taxSummary?.nonTaxableBasis,
      currencyCode,
    ),
    exemptAmount: fmtMoney(transaction.taxSummary?.exemptAmount, currencyCode),
    effectiveTaxRate: transaction.taxSummary?.effectiveTaxRate,
    accrualAmount: fmtMoney(
      transaction.taxSummary?.accrualAmount,
      currencyCode,
    ),
    payToVendorAmount: fmtMoney(
      transaction.taxSummary?.payToVendorAmount,
      currencyCode,
    ),
    vctEffectiveTaxableBasis: fmtMoney(
      transaction.taxSummary?.vctEffectiveTaxableBasis,
      currencyCode,
    ),
    taxVariance: fmtMoney(transaction.taxSummary?.taxVariance, currencyCode),
  };

  const txProps = {
    ...transaction.transactionProperties,
  };

  if (transaction.vctResponseCode) {
    txProps['INVOICE.VCT_RESPONSE_CODE'] = transaction.vctResponseCode;
    txProps['RESULTS.INVOICE.VCT_RESPONSE_CODE'] = transaction.vctResponseCode;
  }

  if (transaction.vctStatus) {
    txProps['INVOICE.VCT_STATUS'] = transaction.vctStatus;
    txProps['RESULTS.INVOICE.VCT_STATUS'] = transaction.vctStatus;
  }

  const row = {
    ...txProps,
    lineCount: transaction.lineCount,
    taxEngineType: 'SBX',
    invoiceNumber: transaction.invoiceNumber,
    currencyCode,
    companyName: transaction.companyName,
    externalCompanyId: transaction.externalCompanyId,
    recordStatus: transaction.recordStatus,
    _details: {
      ...transaction,
      currencyCode,
      invoiceDate:
        formatDate(transaction.transactionDate, 'yyyy-MM-dd') || '--',
      taxSummary,
      addresses: (transaction.addresses || []).map((a) => {
        return Address.fromOneSource(
          a,
          `INVOICE_${a.name.replace('INVOICE.', '')}`,
          a.name,
        );
      }),
      customAttributes: parseCustomAttributes(transaction.customAttributes),
      lines: (transaction.lines || []).map((line, idx) => {
        return mapLine(transaction, idx, line, formatMessage);
      }),
    },
  };

  ['SELLER_ROLE', 'BUYER_ROLE', 'MIDDLEMAN_ROLE'].forEach((k) => {
    const key = `INVOICE.REGISTRATIONS.${k}`;
    if (row[key]) {
      row[key] = flattenReg(row[key]);
    }
  });

  row._details.addresses.forEach((a) => {
    activeLineFields.add(a.key);
    row[a.key] = a;
  });

  switch (row.recordStatus?.toUpperCase()) {
    case 'VALID':
      row.statusLabel = {
        color: 'success',
        text: 'PASS',
      };
      break;
    case 'INVALID':
      row.statusLabel = {
        color: 'danger',
        text: 'FAIL',
      };
      break;
    default:
      row.statusLabel = {
        color: 'warning',
        text: 'ERROR',
      };
  }

  row.activeFields = [...activeLineFields];
  delete row['_batch'];
  delete row['_lines'];
  delete row['_keys'];

  return row;
}

export function mapLine(
  transaction,
  index,
  line,
  formatMessage = (i18nKey, value) => `${i18nKey}.${value?.toUpperCase()}`,
) {
  const rowLine = {
    key: `${transaction.id}_${line.lineNumber}_${index}`,
    currencyCode: transaction.currencyCode || 'USD',
    ...line,
  };

  rowLine.grossAmount = formatCurrency(
    rowLine.grossAmount,
    transaction.transactionProperties || {},
    line,
  );

  const formatKeys = Object.keys(SBX_FORMAT_FIELDS);
  formatKeys.forEach((key) => {
    if (rowLine[key]) {
      rowLine[key] = formatMessage(
        SBX_FORMAT_FIELDS[key],
        rowLine[key]?.toUpperCase(),
      );
    }
  });

  ['SELLER_ROLE', 'BUYER_ROLE', 'MIDDLEMAN_ROLE'].forEach((k) => {
    const key = `LINE.REGISTRATIONS.${k}`;
    if (rowLine[key]) {
      rowLine[key] = flattenReg(rowLine[key]);
    }
  });

  rowLine.validationResults = (line.validationResults || []).map((v) => {
    const validation = { ...v };
    switch (v.name) {
      case 'EXPECTED.LINE.TAXABILITY':
        validation.expectedValue = formatMessage(
          'nextGenTaxabilityType',
          v.expectedValue?.trim(),
        );
        validation.actualValue = v.actualValue
          .split(',')
          .map((e) => {
            return formatMessage('nextGenTaxabilityType', e.trim());
          })
          .join(', ');
        if (validation.actualValue.includes(',')) {
          validation.actualValue = `[${validation.actualValue}]`;
        }
        break;
    }

    if (
      validation.expectedValue.startsWith('[') &&
      validation.expectedValue.endsWith(']') &&
      !validation.expectedValue.includes(',')
    ) {
      validation.expectedValue = validation.expectedValue.substring(
        1,
        validation.expectedValue.length - 1,
      );
    }

    return validation;
  });

  rowLine.addresses = (line.addresses || []).map((a) => {
    const addr = Address.fromOneSource(
      a,
      `LINE_${a.name.replace('LINE.', '')}`,
      a.name,
    );

    addr.label = formatMessage(
      'addressType',
      addr.fieldName.replace('LINE.', ''),
    );

    return addr;
  });

  rowLine.customAttributes = parseCustomAttributes(
    line.customAttributes,
    'LINE',
  );

  return Object.freeze(rowLine);
}

export function isSbxInvoiceField(fieldName) {
  if (
    fieldName.startsWith('INVOICE_') ||
    fieldName.startsWith('INVOICE.') ||
    fieldName.startsWith('RESULTS.INVOICE.')
  ) {
    return true;
  }
  // eslint-disable-next-line no-prototype-builtins
  return SBX_INVOICE_FIELDS_MAP.hasOwnProperty(fieldName);
}
