<template>
  <div class="test-deck-container test-result-review-container">
    <CCard :class="{ 'is-loading': isLoading }">
      <CCardHeader>
        <div style="display: flex">
          <span class="h2"
            >Test Run Results:
            <router-link
              v-show="!!testDeckId"
              :to="{
                name: 'TestDeckDetails',
                params: { testDeckId: testDeckId },
              }"
              >{{ testDeckName }}</router-link
            >
          </span>
          <span
            class="text-muted"
            style="
              margin-left: auto;
              margin-right: 5px;
              margin-top: auto;
              margin-bottom: auto;
            "
          >
            Executed: {{ $format.timestamp(startedAt) }}
          </span>
          <MTestResultDownloadButton
            :id="`btn-test-run-review_${testRunId}`"
            :test-run-id="testRunId"
            variant="ghost"
            size="std"
            style="margin-left: 5px; margin-right: 5px"
          />
          <MDeleteButton
            :id="`btn-delete-test-result_${testRunId}`"
            class="mx-1"
            variant="ghost"
            title="Delete Test Results"
            style="margin-left: 5px; margin-right: 5px"
            @click="handleDelete()"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <SbxTestResultsReview
          v-if="taxEngineType === 'SBX'"
          :test-run-id="testRun.id"
        />
        <VtxTestResultsReview
          v-if="taxEngineType === 'VTX'"
          :test-run-id="testRun.id"
        />
        <AvaTestResultsReview
          v-if="taxEngineType === 'AVA'"
          :test-run-id="testRun.id"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import SbxTestResultsReview from './sbx';
import VtxTestResultsReview from './vtx';
import AvaTestResultsReview from './ava';
import { MTestResultDownloadButton } from '@/components/Buttons';
import api from '@/api';
export default {
  name: 'TestResultsReview',
  components: {
    SbxTestResultsReview,
    VtxTestResultsReview,
    AvaTestResultsReview,
    MTestResultDownloadButton,
  },
  props: {
    testRunId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      testRun: null,
    };
  },
  computed: {
    testDeckId() {
      return this.testRun?.transactionGroupId;
    },
    testDeckName() {
      return this.testRun?.transactionGroupName;
    },
    startedAt() {
      return this.testRun?.startedAt;
    },
    taxEngineType() {
      return this.testRun?.taxEngineType;
    },
  },
  watch: {
    testRunId(v) {
      if (!v) {
        this.testRun = null;
      } else {
        this.fetchTestRun(v).then((res) => {
          this.testRun = res;
        });
      }
    },
  },
  mounted() {
    if (this.testRunId) {
      this.fetchTestRun(this.testRunId).then((res) => {
        this.testRun = res;
      });
    }
  },
  methods: {
    handleDelete() {
      const self = this;
      this.$swal
        .fire({
          title: 'Confirm Delete',
          text: 'Are you sure you wish to delete this test run?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
        .then((res) => {
          if (res.isConfirmed) {
            api.testing
              .deleteTestRunResult(self.testRun.id, self.testRun.taxEngineType)
              .then(() => {
                self.$router.push({ name: 'Testing' });
              })
              .catch((err) => {
                this.$log.error(err);
                this.$swal.fire({
                  title: 'Error',
                  text: 'The test run could not be deleted',
                  icon: 'error',
                });
              });
          }
        });
    },
    fetchTestRun(id) {
      const self = this;
      this.isLoading = true;
      return api.testing
        .getTestRun(id)
        .then((res) => res)
        .finally(() => {
          self.$nextTick(() => {
            self.isLoading = false;
          });
        });
    },
  },
};
</script>
