<template>
  <CCard
    class="validation-result sbx-validation-result"
    :class="{ 'is-valid': value.isValid, 'is-invalid': !value.isValid }"
  >
    <CCardHeader>
      <div><CIcon :name="iconName" :class="iconClasses" size="xl" /></div>
      <div class="text-center">{{ displayLabel }}</div>
    </CCardHeader>
    <CCardBody class="d-flex text-center">
      <div class="validation-value-wrapper">
        <div class="labeled-value-value expected-value">
          {{ expectedValue }}
        </div>
        <label class="text-muted text-small labeled-value-label"
          >Expected</label
        >
      </div>
      <div class="validation-value-wrapper">
        <div
          class="labeled-value-value actual-value"
          :class="{
            'text-danger': !value.isValid && value.isChecked,
            'text-muted': value.isChecked === false,
          }"
        >
          {{ actualValue || message }}
        </div>
        <label
          class="text-small labeled-value-label"
          :class="{
            'text-danger': !value.isValid && value.isChecked === true,
            'text-muted': value.isValid || !value.isChecked,
          }"
          >Actual</label
        >
      </div>
    </CCardBody>
  </CCard>
</template>
<script>
import { SBX_TRANSACTION_FIELDS_MAP } from '@/utils/sbxFieldUtils';

export default {
  name: 'SbxValidationResult',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    statusColor() {
      if (this.value.isValid) {
        return 'success';
      } else {
        if (this.value.isChecked !== false) {
          return 'danger';
        } else {
          return 'warning';
        }
      }
    },
    displayLabel() {
      let field = SBX_TRANSACTION_FIELDS_MAP[this.value.name];
      if (field) {
        return field.label.replace('Expected', '').replace('Exp.', '');
      } else {
        return this.value.name;
      }
    },
    expectedValue() {
      return this.value.expectedValue;
    },
    actualValue() {
      return this.value.actualValue;
    },
    message() {
      return this.value.message;
    },
    iconName() {
      if (this.value.isValid) {
        return 'cis-check-circle';
      } else {
        if (this.value.isChecked !== false) {
          return 'cis-exclamation-circle';
        } else {
          return 'cis-warning';
        }
      }
    },
    iconClasses() {
      if (this.value.isValid) {
        return 'text-success';
      } else {
        if (this.value.isChecked !== false) {
          return 'text-danger';
        } else {
          return 'text-warning';
        }
      }
    },
  },
};
</script>
<style lang="scss">
.validation-result {
  margin-right: 1rem;
  padding: 5px 20px;
  border: 1px solid #d8dbe0 !important;
  margin-top: 1rem;

  .card-header {
    text-align: center;
    font-weight: bold;

    .c-icon {
      margin-bottom: 5px;
    }
  }
  .card-body {
    position: relative;
    padding: 0.25rem 10px;
    justify-content: space-between;
  }

  .validation-value-wrapper {
    padding: 0 0.5rem;
    margin: 0 auto;
  }

  .c-vr {
    display: block;
    width: 1px;
    background-color: rgba(0, 0, 21, 0.2);
  }
}
</style>
