'use strict';
class VtxCounterParty {
  constructor(type, value = {}) {
    this.type = type?.toUpperCase()?.trim() || 'EMPTY';
    this.code = value?.code;
    this.registration = value?.registration;
    this.classCode = value?.classCode;
    this.isBusiness = value?.isBusiness || false;
  }

  get isEmpty() {
    return !this.code && !this.registration;
  }
}

export default VtxCounterParty;
