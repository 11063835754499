import svgMap from 'svgmap';
import Address from '@/models/Address';

import {
  convertPropertyName,
  getFieldLabel,
  mapLineProduct,
} from '@/utils/vtxFieldUtils';

export function reduceJson(map) {
  if (!map) {
    return new Map();
  }

  let reduced = new Map();
  Object.keys(map).forEach((key) => {
    if (map[key]) {
      if (typeof map[key] === 'object') {
        let mapped = reduceJson(map[key]);
        if (!!mapped && mapped.size > 0) {
          reduced.set(key, Object.fromEntries(mapped));
        }
      } else {
        reduced.set(key, map[key]);
      }
    }
  });

  return reduced;
}

export function createSvgMap(countries) {
  return new svgMap({
    targetElementID: 'test-deck-map',
    colorMax: '#0D7930',
    colorMin: '#D6FAE2',
    noDataText: 'No relevant test data',
    flagURL: '/img/countries/svg/{0}.svg',
    data: {
      applyData: 'shipTo',

      data: {
        shipTo: {
          name: 'Ship To',
        },
        shipFrom: {
          name: 'Ship From',
        },
        total: {
          name: 'Total',
        },
      },
      values: countries,
    },
  });
}

export function getSortColumnName(columnName) {
  if (columnName.startsWith('invoice_ship_')) {
    return columnName.split('invoice_').pop();
  }

  return columnName.split('invoice.').pop();
}

export function formatCurrency(amount, document, line) {
  const num = Number.parseFloat(`${amount}`);
  if (isNaN(num)) {
    return '--';
  }

  let currency = document.currencyCode;
  if (line?.lineProperties['LINE CURRENCY CODE']) {
    currency = line['LINE CURRENCY CODE'];
  }

  const locale = navigator.language || navigator.userLanguage || 'en-US';
  if (currency) {
    return Intl.NumberFormat([locale], {
      style: 'currency',
      currency: currency.toUpperCase(),
      minimumFractionDigits: 2,
    }).format(num);
  } else {
    return Intl.NumberFormat([locale], {
      minimumFractionDigits: 2,
    }).format(num);
  }
}

export function mapDocAddresses(transaction) {
  return transaction.addresses.map((a) => {
    const key = `DOC_${a.addressType}_${transaction.id}`;
    let fieldName = a.addressType;
    switch (a.addressType) {
      case 'DESTINATION':
        fieldName = 'DOC_DEST';
        break;
      case 'PHYSICAL_ORIGIN':
        fieldName = 'DOC_PHYS_ORIGIN';
        break;
      case 'ADMINISTRATIVE_DESTINATION':
        fieldName = 'DOC_ADMIN_DEST';
        break;
      case 'ADMINISTRATIVE_ORIGIN':
        fieldName = 'DOC_ADMIN_ORIGIN';
        break;
    }

    return Address.fromVertex(a, key, fieldName);
  });
}

export function mapTransaction(
  transaction,
  formatMessage = (i18nKey, value) => `${i18nKey}.${value}`,
) {
  // const activeLineFields = new Set();
  const currencyCode = transaction.currencyCode || 'USD';
  const filteredTxProps = Object.keys(transaction.transactionProperties).reduce(
    (acc, key) => {
      if (!key.startsWith('_')) {
        const converted = key.replaceAll(' ', '_').toUpperCase();

        acc[converted] = transaction.transactionProperties[key];
      }
      return acc;
    },
    {},
  );

  const row = {
    ...filteredTxProps,
    id: transaction.id,
    testRunId: transaction.testRunId,
    documentNumber: transaction.documentNumber,
    companyCode: transaction.companyCode,
    divisionCode: transaction.divisionCode,
    departmentCode: transaction.departmentCode,
    transactionType: transaction.transactionType,
    documentDate: transaction.documentDate,
    currencyCode,
    transactionProperties: filteredTxProps,
    hasDocumentAddresses: transaction.addresses.length > 0,
    lines: [],
    taxSummary: {
      taxableBasis: 0,
      nonTaxableBasis: 0,
      exemptAmount: 0,
      taxRate: 0,
    },
    activeFields: Object.keys(filteredTxProps),
    recordStatus: transaction.recordStatus?.toUpperCase() || 'ERROR',
    statusLabel: {
      color: 'warning',
      text: 'Error',
    },
    totalTaxAmount: formatCurrency(transaction.totalTaxAmount, transaction),
    validationResults: transaction.validationResults || [],
    documentXML: transaction.documentXML,
    responseXML: transaction.responseXML,
  };

  if (row.transactionType === 'SALE') {
    row.documentType = 'Quote';
  } else {
    row.documentType = 'Purchase';
  }

  row.hasInvoiceValidations = row.validationResults.length > 0;

  mapDocAddresses(transaction).forEach((a) => {
    row[a.fieldName] = a;
  });

  row.lines = transaction.lines.map((line) => {
    // line.activeFields.forEach((f) => activeLineFields.add(f));
    return mapLine(transaction, line, formatMessage);
  });

  switch (row.recordStatus) {
    case 'VALID':
      row.statusLabel = {
        color: 'success',
        text: 'PASS',
      };
      break;
    case 'INVALID':
      row.statusLabel = {
        color: 'danger',
        text: 'FAIL',
      };
      break;
    default:
      row.statusLabel = {
        color: 'warning',
        text: 'ERROR',
      };
  }

  return row;
}

export function mapLine(
  transaction,
  line,
  // formatMessage = (i18nKey, value) => `${i18nKey}.${value?.toUpperCase()}`,
) {
  const lineProperties = Object.keys(line.lineProperties).reduce((acc, key) => {
    if (!key.startsWith('_') && !key.startsWith('tax')) {
      const converted = convertPropertyName(key);
      if (converted) {
        acc[key.replaceAll(' ', '_')] = line.lineProperties[key];
      }
    }
    return acc;
  }, {});

  const rowLine = {
    ...line,
    lineProperties,
    key: `${transaction.id}_${line.lineNumber}`,
    currencyCode: transaction.currencyCode || 'USD',
    taxes: line.responseData?.taxes || [],
    taxSummary: {
      taxableBasis: 0,
      nonTaxableBasis: 0,
      exemptAmount: 0,
      taxRate: 0,
    },
    lineStatus: 'ERROR',
    statusLabel: {
      color: 'success',
      text: 'PASS',
    },
    products: mapLineProduct(line.product),
  };

  if (line.taxSummary) {
    rowLine.taxSummary = line.taxSummary;
  }

  delete rowLine['product'];
  delete rowLine['responseData'];
  rowLine.hasProduct = rowLine.products.length > 0;

  if (transaction.recordStatus === 'ERROR') {
    rowLine.statusLabel = {
      color: 'secondary',
      text: 'Error',
    };
  } else {
    let validationStatus = 'VALID';
    rowLine.validationResults = line.validationResults.map((r) => {
      if (!r.isValid) {
        validationStatus = 'INVALID';
      }
      return Object.freeze({
        label: (getFieldLabel(convertPropertyName(r.name)) || r.name).replace(
          'Exp. ',
          '',
        ),
        ...r,
      });
    });
    rowLine.lineStatus = validationStatus;
    switch (validationStatus) {
      case 'VALID':
        rowLine.statusLabel = {
          color: 'success',
          text: 'Pass',
        };
        break;
      case 'INVALID':
        rowLine.statusLabel = {
          color: 'danger',
          text: 'Fail',
        };
        break;
    }
  }

  rowLine.grossAmount = formatCurrency(rowLine.grossAmount, transaction, line);

  return Object.freeze(rowLine);
}

export function mapCountryData(shipToGeos, shipFromGeos) {
  const countries = Object.keys(shipToGeos || {}).reduce((map, key) => {
    const value = shipToGeos[key] || 0;
    if (!map[key]) {
      map[key] = {
        shipTo: value,
        shipFrom: 0,
        total: value,
      };
    } else {
      map[key].shipTo += value;
      map[key].total += value;
    }
    return map;
  }, {});

  Object.keys(shipFromGeos || {}).forEach((key) => {
    const value = shipFromGeos[key] || 0;
    if (!countries[key]) {
      countries[key] = {
        shipTo: 0,
        shipFrom: value,
        total: value,
      };
    } else {
      countries[key].shipFrom += value;
      countries[key].total += value;
    }
  });

  return countries;
}
