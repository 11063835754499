<template>
  <div class="icon-group">
    <div class="icon-wrapper">
      <slot name="icon">
        <CIcon :class="iconClasses" :name="iconName" :title="title" />
      </slot>
    </div>
    <div class="group-content">
      <slot>
        <span :class="spanClasses">{{ text }}</span>
        <div v-show="!!title" class="group-content-label">{{ title }}</div>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MIconGroup',
  props: {
    iconName: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'xxl',
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    iconClasses() {
      let classes = [];
      classes.push(`c-icon-${this.size}`);

      return classes;
    },
    spanClasses() {
      let classes = [];
      switch (this.size) {
        case 'xxl':
          classes.push('h3');
          break;
        case 'xl':
          classes.push('h4');
          break;
        default:
          classes.push('h5');
          break;
      }

      return classes;
    },
  },
};
</script>
<style lang="scss">
.icon-group {
  display: flex;
  .icon-wrapper,
  .group-content {
    border: 1px solid #e4e7ea;
    border-radius: 5px;
    margin: 0;
    padding: calc(0.375rem + 3px) 0.75rem;
  }
  .icon-wrapper {
    background-color: #f0f3f5;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    display: inline-flex;
    & > * {
      margin: auto;
    }
  }

  .group-content {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
    height: 100%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      margin-bottom: 0;
    }
    .group-content-label,
    label {
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 65%;
      color: #768192;
      margin-bottom: -6px;
    }
  }
}
</style>
