<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol col="1">
          <MRecordStatusBadge
            class="h2"
            :value="line.lineStatus"
            style="margin-top: auto; margin-bottom: auto"
          />
        </CCol>
        <CCol>
          <div class="w-100 d-flex">
            <template v-if="line.products.length > 0">
              <LabeledValue
                v-for="(product, index) in line.products"
                :key="`${product.type}_${index}`"
                :label="$t(`productType.${product.type}`)"
              >
                <span class="h4">{{ product.code || '--' }}</span>
              </LabeledValue>
            </template>
            <LabeledValue label="Quantity">
              <span class="h4">{{ line.quantity || '--' }}</span>
            </LabeledValue>
            <LabeledValue label="Gross">
              <span class="h4">{{ line.grossAmount || '--' }}</span>
            </LabeledValue>
            <LabeledValue v-if="!!taxSummary.taxVariance" label="Tax Variance">
              <span class="h4">{{
                $format.currency(line.currencyCode, taxSummary.taxVariance)
              }}</span>
            </LabeledValue>
          </div>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow class="pb-4">
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Taxable Basis</small><br />
            <strong class="h4">{{
              $format.currency(line.currencyCode, taxSummary.taxableBasis)
            }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Total Tax Amount</small><br />
            <strong class="h4">{{
              $format.currency(line.currencyCode, line.totalTaxAmount)
            }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Tax Rate</small><br />
            <strong class="h4">{{
              $format.percent(taxSummary.taxRate, 4)
            }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Eff. Tax Rate</small><br />
            <strong class="h4">{{
              $format.percent(taxSummary.effectiveTaxRate, 4)
            }}</strong>
          </CCallout>
        </CCol>
      </CRow>
      <CRow v-if="!!taxSummary.accrualAmount || !!taxSummary.payToVendorAmount">
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Accrual Amount</small><br />
            <strong class="h4">{{
              $format.currency(line.currencyCode, taxSummary.accrualAmount) ||
              '--'
            }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="seconday">
            <small class="text-muted">Pay to Vendor Amount</small><br />
            <strong class="h4">{{
              $format.currency(
                line.currencyCode,
                taxSummary.payToVendorAmount,
              ) || '--'
            }}</strong>
          </CCallout>
        </CCol>
        <CCol></CCol>
        <CCol></CCol>
      </CRow>
      <CRow v-if="line.hasLineValidationResults">
        <CCol>
          <h4>Line Level Validations</h4>
        </CCol>
      </CRow>

      <CRow v-if="line.hasLineValidationResults" class="pb-4">
        <CCol
          v-for="(v, vIdx) in line.validationResults"
          :key="`${vIdx}_${v.name}`"
          col="6"
        >
          <SbxValidationResult :value="v" />
        </CCol>
      </CRow>
      <CRow v-if="lineTaxes.length > 0">
        <CCol>
          <div class="w-100 d-flex">
            <div>
              <span class="h4">
                Line Level Taxes
                <span class="text-muted">({{ lineTaxes.length }})</span>
              </span>
            </div>
            <MCollapseToggler :value.sync="showTaxDetails" />
          </div>
          <hr />
        </CCol>
      </CRow>
      <CCollapse v-if="lineTaxes.length > 0" :show="showTaxDetails">
        <CRow
          v-for="(lineTax, tIdx) in lineTaxes"
          :key="`${tIdx}_${lineTax.addressType}_${lineTax.ruleOrder}_${lineTax.erpTaxCode}`"
        >
          <CCol>
            <CCard class="line-tax-details-card">
              <CCardHeader>
                <CRow class="pb-2 tax-title-row">
                  <CCol>
                    <div class="w-100 d-flex">
                      <div class="h5">
                        <span class="pr-1"
                          ><strong>{{ tIdx + 1 }}.</strong></span
                        >
                        <strong
                          >{{ lineTax.addressTypeLabel }}:
                          {{ lineTax.authorityName }}</strong
                        >
                      </div>
                      <div v-if="lineTax.isExempt">
                        <CBadge color="primary" class="ml-3">Exempt</CBadge>
                      </div>
                      <div v-else>
                        <CBadge
                          :color="
                            lineTax.taxRateCode === 'NL' ? 'secondary' : 'info'
                          "
                          class="ml-3"
                          >{{ lineTax.taxRateCode }}</CBadge
                        >
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <CRow>
                      <CCol>
                        <fieldset>
                          <legend>Tax Authority</legend>
                          <div class="d-flex w-100">
                            <LabeledValue
                              label="Address Type"
                              :value="lineTax.addressType"
                            />
                            <LabeledValue
                              label="Zone Level"
                              :value="lineTax.zoneLevel"
                            />
                            <LabeledValue
                              label="Authority Type"
                              :value="lineTax.authorityType"
                            />
                            <LabeledValue
                              label="Authority Name"
                              :value="lineTax.authorityName"
                            />
                          </div>
                        </fieldset>
                      </CCol>
                      <CCol
                        v-if="
                          !!lineTax.buyerRegistration ||
                          !!lineTax.sellerRegistration
                        "
                      >
                        <fieldset>
                          <legend>Tax Registrations</legend>
                          <div class="w-100 d-flex">
                            <LabeledValue
                              label="Buyer"
                              :value="lineTax.buyerRegistration || '--'"
                            />
                            <LabeledValue
                              label="Seller"
                              :value="lineTax.sellerRegistration || '--'"
                            />
                          </div>
                        </fieldset>
                      </CCol>
                    </CRow>
                    <CRow class="pb-2">
                      <CCol>
                        <fieldset>
                          <div class="d-flex w-100">
                            <LabeledValue
                              label="Country"
                              :value="lineTax.country"
                            />
                            <LabeledValue
                              v-if="!!lineTax.province"
                              label="Province"
                              :value="lineTax.province"
                            />
                            <LabeledValue
                              v-if="!!lineTax.state"
                              label="State"
                              :value="lineTax.state"
                            />
                            <LabeledValue
                              v-if="!!lineTax.county"
                              label="County"
                              :value="lineTax.county"
                            />
                            <LabeledValue
                              v-if="!!lineTax.city"
                              label="City"
                              :value="lineTax.city"
                            />
                            <LabeledValue
                              v-if="!!lineTax.postCode"
                              label="Postal"
                              :value="lineTax.postCode"
                            />
                          </div>
                        </fieldset>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol>
                    <fieldset>
                      <legend>Tax Properties</legend>
                      <CRow>
                        <CCol>
                          <div class="d-flex w-100">
                            <LabeledValue
                              v-if="lineTax.taxType"
                              label="Type"
                              :value="lineTax.taxType"
                            />
                            <LabeledValue
                              v-if="lineTax.taxDirection"
                              label="Direction"
                              :value="lineTax.taxDirection"
                            />
                            <LabeledValue
                              label="Exempt"
                              :value="lineTax.isExempt ? 'Yes' : 'No'"
                            />
                            <LabeledValue
                              label="Tax Rate"
                              :value="
                                $format.percent(lineTax.taxRate, 4) || '--'
                              "
                            />
                            <LabeledValue
                              label="Tax Rate Code"
                              :value="lineTax.taxRateCode"
                            />
                            <LabeledValue
                              label="Rule Order"
                              :value="lineTax.ruleOrder"
                            />
                            <LabeledValue
                              label="ERP Tax Code"
                              :value="lineTax.erpTaxCode"
                            />
                          </div>
                        </CCol>
                      </CRow>
                      <CRow
                        v-if="
                          !!lineTax.vctEffectiveTaxableBasis ||
                          !!lineTax.vendorTax
                        "
                      >
                        <CCol>
                          <div class="d-flex w-100">
                            <LabeledValue
                              label="Vendor Tax"
                              :value="
                                $format.currency(
                                  line.currencyCode,
                                  lineTax.vendorTax,
                                ) || '--'
                              "
                            />
                            <LabeledValue
                              label="VCT Eff. Tax Basis"
                              :value="
                                $format.currency(
                                  line.currencyCode,
                                  lineTax.vctEffectiveTaxableBasis,
                                ) || '--'
                              "
                            />
                          </div>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <div class="d-flex w-100">
                            <LabeledValue
                              label="Invoice Description"
                              :value="lineTax.invoiceDescription"
                            />
                            <LabeledValue
                              label="Jurisdiction Text"
                              style="max-width: 300px"
                            >
                              <span class="text-small">{{
                                lineTax.jurisdictionText
                              }}</span>
                            </LabeledValue>
                          </div>
                        </CCol>
                      </CRow>
                    </fieldset>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCollapse>

      <CRow class="pt-4">
        <CCol>
          <div class="w-100 d-flex">
            <span class="h4">Line Input Properties</span>
            <MCollapseToggler :value.sync="showLineInput" />
          </div>
          <hr />
        </CCol>
      </CRow>
      <CCollapse :show="showLineInput">
        <CRow
          v-if="line.hasRegistrations || line.hasCounterPartyInfo"
          class="pb-4"
        >
          <CCol v-if="line.hasRegistrations">
            <SbxRegistrationsBlock v-bind="line.registrations" />
          </CCol>
          <CCol v-if="line.hasCounterPartyInfo">
            <SbxCounterPartyBlock v-bind="line.counterParty" />
          </CCol>
        </CRow>
        <CRow class="pb-4">
          <CCol>
            <fieldset>
              <legend>User Elements</legend>
              <div
                v-if="line.hasCustomAttributes"
                class="user-element-container"
              >
                <LabeledValue
                  v-for="e in line.customAttributes"
                  :key="e.key"
                  :label="e.label"
                  :value="e.value"
                />
              </div>
              <div v-else class="text-muted h4 mt-2 ml-1">None</div>
            </fieldset>
          </CCol>
        </CRow>
        <CRow v-if="line.addresses.length > 0" class="pb-4">
          <CCol>
            <fieldset>
              <legend>Addresses</legend>
              <CRow class="pt-2">
                <CCol
                  v-for="(address, addressIndex) in line.addresses"
                  :key="`${line.lineNumber}_${addressIndex}_${address.name}`"
                  col="6"
                  md="4"
                >
                  <DocumentAddress :value="address" :label="address.label" />
                </CCol>
              </CRow>
            </fieldset>
          </CCol>
        </CRow>
        <CRow v-if="(line.lineAttributes || []).length > 0" class="pb-4">
          <CCol>
            <fieldset>
              <legend>Line Attributes</legend>
              <div class="user-element-container">
                <LabeledValue
                  v-for="e in line.lineAttributes"
                  :key="e.key"
                  :label="e.label"
                >
                  <MCheckIcon
                    v-if="e.fieldType === 'BOOLEAN'"
                    :value="e.value"
                    show-false
                  />
                  <span v-else>
                    {{ e.value }}
                  </span>
                </LabeledValue>
              </div>
            </fieldset>
          </CCol>
        </CRow>
      </CCollapse>
    </CCardBody>
  </CCard>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
import SbxValidationResult from '../SbxValidationResult';
import MRecordStatusBadge from '@/components/MRecordStatusBadge';

export default {
  name: 'SbxInvoiceLineResultTab',
  components: { DocumentAddress, SbxValidationResult, MRecordStatusBadge },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLineInput: false,
      showTaxDetails: false,
    };
  },
  computed: {
    vct() {
      if (!this.line.vct) {
        return null;
      }

      return this.line.vct;
    },
    taxSummary() {
      return {
        taxableBasis: this.line.taxSummary?.taxableBasis || null,
        taxRate: this.line.taxSummary?.taxRate || null,
        effectiveTaxRate: this.line.taxSummary?.effectiveTaxRate || null,
        accrualAmount: this.line.taxSummary?.accrualAmount || null,
        payToVendorAmount: this.line.taxSummary?.payToVendorAmount || null,
        taxVariance: this.line.taxSummary?.taxVariance || null,
        vctEffectiveTaxableBasis:
          this.line.taxSummary?.vctEffectiveTaxableBasis || null,
      };
    },
    lineTaxes() {
      return (this.line?.taxes || []).map((t) => {
        const v = {
          ...t,
        };
        switch (t.addressType) {
          case 'ST':
            v.addressTypeLabel = 'Ship To';
            break;
          case 'SF':
            v.addressTypeLabel = 'Ship From';
            break;
          default:
            v.addressTypeLabel = v.addressType;
            break;
        }

        return v;
      });
    },
  },
};
</script>
