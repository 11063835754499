import { render, staticRenderFns } from "./SbxTestResultsReview.vue?vue&type=template&id=0b4bf1ba"
import script from "./SbxTestResultsReview.vue?vue&type=script&lang=js"
export * from "./SbxTestResultsReview.vue?vue&type=script&lang=js"
import style0 from "./SbxTestResultsReview.vue?vue&type=style&index=0&id=0b4bf1ba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports