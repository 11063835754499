<template>
  <CRow :id="lineKey" class="line-detail-row sbx-line-detail-row">
    <CCol col="1" class="line-number-col">
      <div class="text-uppercase text-muted small">Line</div>
      <div class="text-value-lg">{{ lineNumber }}</div>
    </CCol>

    <CCol
      col="4"
      class="expectations-col"
      :class="{ 'empty-col': !hasResults }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasResults,
          'c-callout-success': lineStatus === 'VALID',
          'c-callout-danger': lineStatus !== 'VALID',
        }"
      >
        Results
      </div>
      <div v-if="hasResults" class="row validations-list">
        <CCol>
          <table class="validation-result-table dt-density-dense">
            <tbody>
              <tr
                v-for="result in validationResults"
                :key="`${lineKey}_${result.name}`"
                class="validation-result-row"
              >
                <td class="label">{{ result.label }}</td>
                <td>
                  <CBadge
                    v-if="!result.isValid"
                    :color="result.isValid ? 'success' : 'danger'"
                  >
                    {{ result.isValid ? 'PASS' : 'FAIL' }}
                  </CBadge>
                </td>
                <td>
                  <div class="d-flex">
                    <LabeledValue label="Expected">
                      {{ result.expectedValue }}
                    </LabeledValue>
                    <LabeledValue label="Actual">
                      {{ result.actualValue }}
                    </LabeledValue>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </div>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
    <CCol col="3">
      <div class="col-label c-callout c-callout-primary">Tax Summary</div>
      <dl class="row">
        <dt class="col-5">Taxable Amount</dt>
        <dd class="col-7">
          {{
            taxSummary.taxableAmount > 0
              ? $format.currency(value.currencyCode, taxSummary.taxableAmount)
              : '--'
          }}
        </dd>
        <dt class="col-5">Total Tax</dt>
        <dd class="col-7">
          {{
            taxSummary.totalTaxAmount > 0
              ? $format.currency(value.currencyCode, taxSummary.totalTaxAmount)
              : '--'
          }}
        </dd>
        <dt class="col-5">Tax Rate</dt>
        <dd class="col-7">
          {{
            taxSummary.taxRate > 0
              ? $format.percent(taxSummary.taxRate, 4)
              : '--'
          }}
        </dd>
        <dt class="col-5">Effective Tax Rate</dt>
        <dd class="col-7">
          {{
            taxSummary.effectiveTaxRate > 0
              ? $format.percent(taxSummary.effectiveTaxRate, 4)
              : '--'
          }}
        </dd>
      </dl>
    </CCol>
    <CCol col="3" class="product-col">
      <div class="col-label c-callout c-callout-primary">Product</div>
      <dl class="row product-list">
        <dt class="col-5">Item Code</dt>
        <dd class="col-7">
          <pre>{{ itemCode || '--' }}</pre>
        </dd>
        <dt class="col-5">Quantity:</dt>
        <dd class="col-7">
          <pre>{{ quantity || '--' }}</pre>
        </dd>
        <dt class="col-5">Amount:</dt>
        <dd class="col-7">
          <pre>{{ grossAmount || '--' }}</pre>
        </dd>
      </dl>
    </CCol>
  </CRow>
</template>
<script>
import { getFieldForProperty } from '@/utils/ava/avaFieldUtils';
import { titleCase } from '@/utils/helpers';
export default {
  name: 'AvaDocumentLineResult',
  props: {
    value: {
      type: Object,
      required: true,
    },
    docStatus: {
      type: String,
      required: false,
      default: 'FAILED',
    },
  },
  data() {
    return {
      lineKey: null,
      lineNumber: 1,
      quantity: 0,
      grossAmount: 0,
      itemCode: null,
      validationResults: [],
      userDefinedFields: [],
      // taxSummary: {
      //   totalTaxAmount: 0,
      //   effectiveTaxRate: 0,
      //   taxableAmount: 0,
      //   taxRate: 0,
      // },
    };
  },
  computed: {
    taxSummary() {
      return {
        totalTaxAmount: this.value?.totalTaxAmount,
        effectiveTaxRate: this.value?.effectiveTaxRate,
        taxableAmount: this.value?.taxableAmount,
        taxRate: this.value?.taxRate,
      };
    },
    hasResults() {
      return this.validationResults.length > 0;
    },
    hasUserDefinedFields() {
      return this.userDefinedFields.length > 0;
    },
    lineStatus() {
      if (this.docStatus === 'FAILED') {
        return 'FAILED';
      }

      if (this.value.isValid) {
        return 'VALID';
      } else {
        return 'INVALID';
      }
    },
  },
  mounted() {
    const v = this.value;
    this.lineKey = v.key;
    this.lineNumber = v.lineNumber;
    this.quantity = this.$format.number(v.quantity);
    this.grossAmount = this.$format.currency(v.currencyCode, v.amount);
    this.itemCode = v.itemCode;
    // this.taxSummary.totalTaxAmount = v.totalTaxAmount;
    // this.taxSummary.effectiveTaxRate = v.effectiveTaxRate;
    // this.taxSummary.taxRate = v.taxRate;
    // this.taxSummary.taxableAmount = v.taxableAmount;

    this.userDefinedFields = Object.keys(v.userDefinedFields || {})
      .map((field) => {
        return Object.freeze({
          name: field,
          label: field,
          value: v.userDefinedFields[field],
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.validationResults = (v.validationResults || [])
      .map((e) => {
        const field = getFieldForProperty(e.name);

        return Object.freeze({
          ...e,
          label: titleCase(
            field?.label?.replace('EXP. LINE ', '') ||
              e.name.replace('EXPECTED.LINE.'),
            true,
          ),
        });
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });
  },
};
</script>
