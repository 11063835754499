<template>
  <CCard
    class="validation-result ava-validation-result"
    :class="{ 'is-valid': value.isValid, 'is-invalid': !value.isValid }"
  >
    <CCardHeader>
      <div><CIcon :name="iconName" :class="iconClasses" size="xl" /></div>
      <div class="text-center">{{ displayLabel }}</div>
      <div v-if="isError" class="text-center text-small text-muted">
        {{ errorMessage }}
      </div>
    </CCardHeader>
    <CCardBody v-if="!isError" class="d-flex text-center">
      <div class="validation-value-wrapper">
        <div class="labeled-value-value expected-value">
          {{ expectedValue }}
        </div>
        <label class="text-muted text-small labeled-value-label"
          >Expected</label
        >
      </div>
      <!-- <div class="c-vr" /> -->
      <div class="validation-value-wrapper">
        <div
          class="labeled-value-value actual-value"
          :class="{
            'text-danger': !value.isValid,
            'text-warning': value.isValid && !value.isChecked,
          }"
        >
          {{ actualValue }}
        </div>
        <label
          class="text-small labeled-value-label"
          :class="{
            'text-warning': value.isValid && !value.isChecked,
            'text-danger': !value.isValid,
            'text-muted': value.isValid,
          }"
          >Actual</label
        >
      </div>
    </CCardBody>
    <CCardBody v-else>
      <div class="text-center">{{ errorDescription }}</div>
    </CCardBody>
  </CCard>
</template>
<script>
import { getFieldForProperty } from '@/utils/ava/avaFieldUtils';
import { titleCase } from '@/utils/helpers';
export default {
  name: 'AvaValidationResult',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isError: false,
      errorMessage: null,
      errorCode: null,
      errorDescription: null,
      errorHelpLink: null,
    };
  },
  computed: {
    statusColor() {
      if (this.value.isValid) {
        if (this.value.isChecked) {
          return 'success';
        } else {
          return 'warning';
        }
      } else {
        return 'danger';
      }
    },
    displayLabel() {
      let field = getFieldForProperty(this.value.name);
      if (field) {
        return titleCase(
          field.label
            .replace('EXPECTED ', '')
            .replace('EXP. ', '')
            .replace('DOC ', '')
            .replace('LINE ', ''),
          true,
        );
      } else {
        return titleCase(
          this.value.name
            .replace('EXPECTED.', '')
            .replace('DOCUMENT.', '')
            .replace('LINE.', '')
            .replace('_', ' '),
          true,
        );
      }
    },
    expectedValue() {
      return this.value.expectedValue;
    },
    actualValue() {
      return this.value.actualValue;
    },
    iconName() {
      if (this.value.isValid) {
        return 'cis-check-circle';
      } else {
        return 'cis-exclamation-circle';
      }
    },
    iconClasses() {
      if (this.value.isValid) {
        return 'text-success';
      } else {
        return 'text-danger';
      }
    },
  },
  mounted() {
    if (this.value?.properties?.errorResult) {
      this.isError = true;
      const error = this.value.properties.errorResult.error;
      let details;
      if (error.details && error.details.length > 0) {
        details = error.details[0];
      }
      this.errorCode = error.code;
      this.errorDescription = details?.description;
      this.errorMessage = details?.message || error.message;
      if (this.errorMessage?.endsWith('.')) {
        this.errorMessage = this.errorMessage.substring(
          0,
          this.errorMessage.length - 1,
        );
      }
      this.errorHelpLink = details?.helpLink;
    }
  },
};
</script>
<style lang="scss">
.validation-result {
  margin-right: 1rem;
  padding: 5px 20px;
  border: 1px solid #d8dbe0 !important;
  margin-top: 1rem;

  .card-header {
    text-align: center;
    font-weight: bold;

    .c-icon {
      margin-bottom: 5px;
    }
  }
  .card-body {
    position: relative;
    padding: 0.25rem 10px;
    justify-content: space-between;
  }

  .validation-value-wrapper {
    padding: 0 0.5rem;
    margin: 0 auto;
  }

  .c-vr {
    display: block;
    width: 1px;
    background-color: rgba(0, 0, 21, 0.2);
  }
}
</style>
