<template>
  <div class="test-deck-review-body ava-test-results-review-body">
    <CRow>
      <CCol>
        <CCard class="no-border">
          <CCardBody>
            <CRow>
              <CCol col="6">
                <CRow>
                  <CCol>
                    <div class="m-0 d-flex">
                      <MIconGroup
                        icon-name="cil-inbox"
                        title="Test Deck"
                        :text="testDeckName"
                      />
                      <MIconGroup
                        class="ml-2"
                        icon-name="cil-ethernet"
                        title="Connection"
                        :text="connectivityName"
                      />
                      <MIconGroup
                        class="ml-2"
                        icon-name="cil-calendar"
                        title="Default Document Date"
                        :text="
                          $format.date(transactionDate, 'yyyy-MM-dd') || '--'
                        "
                      />
                      <MIconGroup
                        class="ml-2"
                        icon-name="cil-speedometer"
                        title="Pass Rate"
                        :text="$format.percent(validPercent)"
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <CCallout
                      color="secondary"
                      class="clickable"
                      @click.native.prevent="setRecordStatusFilter([])"
                    >
                      <small class="text-muted">Total Documents</small><br />
                      <strong class="h4">{{
                        $format.number(itemCount)
                      }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol>
                    <CCallout
                      color="success"
                      class="clickable"
                      @click.native.prevent="setRecordStatusFilter(['VALID'])"
                    >
                      <small class="text-muted">Passed</small><br />
                      <strong class="h4">{{
                        $format.number(validCount)
                      }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol>
                    <CCallout
                      color="danger"
                      class="clickable"
                      @click.native.prevent="setRecordStatusFilter(['INVALID'])"
                    >
                      <small class="text-muted">Failed</small><br />
                      <strong class="h4">{{
                        $format.number(invalidCount)
                      }}</strong>
                    </CCallout>
                  </CCol>
                  <CCol>
                    <CCallout
                      color="warning"
                      class="clickable"
                      @click.native.prevent="setRecordStatusFilter(['FAILED'])"
                    >
                      <small class="text-muted">Errors</small><br />
                      <strong class="h4">{{
                        $format.number(errorCount)
                      }}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="mt-4">
      <CCol>
        <CCard>
          <CCardHeader class="d-flex">
            <div class="h4 card-title">
              <CIcon name="cil-globe-alt" class="mr-2" size="lg" />Testing Map
            </div>
            <div style="margin-left: auto; margin-right: 0">
              <CToggler
                in-header
                @click.prevent="
                  () => {
                    if (!isLoading) {
                      if (showMap) {
                        clearMapToolTips();
                      }
                      showMap = !showMap;
                    }
                  }
                "
              >
                <CIcon
                  :name="showMap ? 'cis-chevron-bottom' : 'cis-chevron-left'"
                />
              </CToggler>
            </div>
          </CCardHeader>
          <CCollapse :show.sync="showMap">
            <CCardBody class="map-wrapper">
              <div id="test-deck-map" class="map-container"></div>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard class="filters-card">
          <CCardHeader>
            <CRow>
              <CCol col="4">
                <h4 class="card-title">
                  <CIcon name="cil-filter" class="mr-2" size="lg" />Filters
                </h4>
              </CCol>
              <CCol col="8">
                <div class="d-flex">
                  <div style="margin-left: auto; margin-right: 0">
                    <template v-if="!showFilters">
                      <CBadge
                        v-for="label in filterLabels"
                        :id="`bdg-filter-label-badge_${label.filterKey}`"
                        :key="label.filterKey"
                        class="h5 ml-2 filter-label-badge"
                        :color="label.color"
                        >{{ label.label
                        }}<small v-if="label.labelSubText">{{
                          label.labelSubText
                        }}</small>
                        <CIcon
                          :id="`btn-filter-label-badge-close_${label.filterKey}`"
                          name="cil-x-circle"
                          class="ml-2 clickable badge-close"
                          title="Remove Filter"
                          role="button"
                          @click.native="removeFilter(label.filterKey)"
                        />
                      </CBadge>
                    </template>
                    <CToggler
                      id="tgl-test-deck-review-filters"
                      class="ml-2"
                      in-header
                      @click.prevent="
                        () => {
                          showFilters = !showFilters;
                        }
                      "
                    >
                      <CIcon
                        :name="
                          showFilters
                            ? 'cis-chevron-bottom'
                            : 'cis-chevron-left'
                        "
                      />
                    </CToggler>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCollapse :show.sync="showFilters">
            <CCardBody>
              <CRow>
                <CCol col="4">
                  <label for="test-deck-filter-ship-to">Ship To</label>
                  <MGeoFilter
                    id="test-deck-filter-ship-to"
                    :options="shipToGeos"
                    :value.sync="selectedShipToGeos"
                  />
                </CCol>
                <CCol col="2" class="text-center">
                  <div>
                    <label for="test-deck-filter-logic">Geo Filter Logic</label>
                  </div>
                  <CButton
                    id="test-deck-filter-logic"
                    color="info"
                    @click="toggleGeoLogic"
                    >{{ geoLogic }}</CButton
                  >
                  <div class="text-note mt-1">
                    {{
                      geoLogic === 'AND'
                        ? 'Only include Ship To from Ship From'
                        : 'Include Ship To or Ship From'
                    }}
                  </div>
                </CCol>
                <CCol col="4">
                  <label for="test-deck-filter-ship-from">Ship From</label>
                  <MGeoFilter
                    id="test-deck-filter-ship-from"
                    :options="shipFromGeos"
                    :value.sync="selectedShipFromGeos"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol col="4">
                  <label for="test-deck-filter-document-type"
                    >Document Type</label
                  >
                  <VSelect
                    id="test-deck-filter-document-type"
                    v-model="selectedDocumentTypes"
                    :options="documentTypesOptions"
                    track-by="value"
                    label="label"
                    searchable
                    :close-on-select="false"
                    :limit="3"
                    multiple
                  />
                </CCol>
                <CCol col="4">
                  <label for="test-deck-filter-currency-code"
                    >Currency Code</label
                  >
                  <VSelect
                    id="test-deck-filter-currency-code"
                    v-model="selectedCurrencyCodes"
                    :options="currencyCodesOptions"
                    track-by="value"
                    label="label"
                    searchable
                    :close-on-select="false"
                    :limit="3"
                    multiple
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <MDataTable
          id="ava-test-results-review-table"
          :fields="fields"
          fixed
          striped
          :loading="isLoading"
          :items="content"
          :table-filter="{ external: true, lazy: true }"
          :column-filter="false"
          :sorter="{ external: true }"
          :sorter-value.sync="columnSorter"
          :table-filter-value.sync="tableFilterValue"
          :pagination="false"
          :per-page="pageSize"
        >
          <template #recordStatus="{ item }">
            <CBadge :color="item.statusLabel.color">
              {{ item.statusLabel.text }}
            </CBadge>
          </template>
          <template #DOCUMENT_BILL_TO="{ item }">
            <ZoneAddress :value="item['DOCUMENT_BILL_TO']" />
          </template>
          <template #DOCUMENT_GOODS_PLACE_OR_SERVICE_RENDERED="{ item }">
            <ZoneAddress
              :value="item['DOCUMENT_GOODS_PLACE_OR_SERVICE_RENDERED']"
            />
          </template>
          <template #DOCUMENT_IMPORT_ADDRESS="{ item }">
            <ZoneAddress :value="item['DOCUMENT_IMPORT_ADDRESS']" />
          </template>
          <template #DOCUMENT_POINT_OF_ORDER_ACCEPTANCE="{ item }">
            <ZoneAddress :value="item['DOCUMENT_POINT_OF_ORDER_ACCEPTANCE']" />
          </template>
          <template #DOCUMENT_POINT_OF_ORDER_ORIGIN="{ item }">
            <ZoneAddress :value="item['DOCUMENT_POINT_OF_ORDER_ORIGIN']" />
          </template>
          <template #DOCUMENT_SHIP_FROM="{ item }">
            <ZoneAddress :value="item['DOCUMENT_SHIP_FROM']" />
          </template>
          <template #DOCUMENT_SHIP_TO="{ item }">
            <ZoneAddress :value="item['DOCUMENT_SHIP_TO']" />
          </template>
          <template #DOCUMENT_SINGLE_LOCATION="{ item }">
            <ZoneAddress :value="item['DOCUMENT_SINGLE_LOCATION']" />
          </template>

          <template #table-controls>
            <MTablePaginator
              id="pg-test-deck-review-paginator"
              :page.sync="pageNumber"
              :per-page.sync="pageSize"
              :total-rows="totalCount"
              :page-count="totalPages"
              :filtered-rows="content.length"
              :filtering="false"
            >
            </MTablePaginator>
          </template>
          <template #details="{ item }">
            <div class="invoice-line-details-wrapper">
              <CCard
                accent-color="info"
                align="left"
                class="invoice-line-details-card"
              >
                <CCardHeader>
                  <div class="d-flex">
                    <MDetailsButton
                      :id="`btn-show-invoice-details_${item.id}`"
                      @click="doshowDocumentDetails(item)"
                    />
                    <span
                      class="h4 ml-2"
                      style="margin-top: auto; margin-bottom: auto"
                      >&nbsp;Line Details: {{ item['DOCUMENT.CODE'] }}</span
                    >
                    <span
                      class="text-muted"
                      style="
                        margin-left: 5px;
                        margin-top: auto;
                        margin-bottom: auto;
                      "
                      >({{ item._lines.length }}
                      {{ $plural('entity.line', item._lines.length) }})</span
                    >
                  </div>
                </CCardHeader>
                <CCardBody class="invoice-line-details-container">
                  <AvaDocumentLineResult
                    v-for="line in item._lines"
                    :key="line.key"
                    :value="line"
                    :doc-status="item._value.recordStatus"
                  />
                </CCardBody>
              </CCard>
            </div>
          </template>
        </MDataTable>
      </CCol>
    </CRow>

    <AvaDocumentResultsDetailsModal
      v-if="showDocumentDetails"
      id="mod-ava-document-result-details"
      :value="selectedDocument"
      :show.sync="showDocumentDetails"
    />
  </div>
</template>
<script>
import { mapCountryDataForSvg } from '@/utils/parsing/testDeck';
// import TestDeckCalloutRow from '@/views/testing/decks/TestDeckCalloutRow';
import { createSvgMap, mapDocument } from './AvaTestResultsReview.js';

import api from '@/api';
import MDataTable from '@/components/MDataTable/MDataTable';
import MTablePaginator from '@/components/MDataTable/MTablePaginator';
import ZoneAddress from '@/views/testing/_components/ZoneAddress';
import AvaDocumentResultsDetailsModal from '@/views/testing/results/ava/_components/AvaDocumentResultsDetailsModal';
import { MGeoFilter } from '@/components/form/MGeoPicker';
import { MDetailsButton } from '@/components/Buttons';
import {
  getFieldForProperty,
  AVA_DOC_TABLE_COLS,
} from '@/utils/ava/avaFieldUtils';
import AvaDocumentLineResult from '@/views/testing/results/ava/_components/AvaDocumentLineResult.vue';

import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import MIconGroup from '@/components/MIconGroup';
export default {
  name: 'AvaTestResultsReview',
  components: {
    MDataTable,
    MTablePaginator,
    ZoneAddress,
    AvaDocumentLineResult,
    MGeoFilter,
    MDetailsButton,
    MIconGroup,
    AvaDocumentResultsDetailsModal,
  },
  props: {
    testRunId: {
      type: String,
      required: true,
    },
  },
  $svgMap: null,
  data() {
    return {
      showTestDeckRunModal: false,
      showFilters: false,
      taxEngineType: 'AVA',
      isLoading: false,
      page: 1,
      size: 50,
      content: [],
      totalCount: 0,
      totalPages: 1,
      transactionFields: [],
      internalTableFilterValue: null,
      internalColumnFilterValue: null,
      internalSorter: {
        column: 'document_code',
        asc: true,
      },
      shipToGeos: [],
      shipFromGeos: [],
      currencyCodes: [],
      documentTypes: [],
      filters: {
        shipFrom: null,
        shipTo: null,
        currencyCodes: null,
        documentTypes: null,
        recordStatus: null,
      },
      testRun: null,
      showMap: false,
      mapCountries: {},
      selectedDocument: null,
      showDocumentDetails: false,
      geoLogic: 'AND',
    };
  },
  computed: {
    testDeckName() {
      return this.testRun?.testDeck?.name || 'Loading...';
    },
    connectivityName() {
      return this.testRun?.connectivity?.name || '--';
    },
    itemCount() {
      return this.testRun?.itemCount || 0;
    },
    invalidCount() {
      return this.testRun?.invalidCount || 0;
    },
    validCount() {
      return this.testRun?.validCount || 0;
    },
    errorCount() {
      return this.testRun?.errorCount || 0;
    },
    validPercent() {
      return this.testRun?.validPercent || 0;
    },
    transactionDate() {
      return this.testRun?.transactionDate || new Date();
    },
    selectedShipFromGeos: {
      get() {
        return this.filters.shipFrom;
      },
      set(v) {
        this.filters.shipFrom = v;
        this.fetchTestDeck(this.testRunId, {
          ...this.queryArgs,
          page: 0,
          shipFrom: v,
        });
      },
    },
    selectedShipToGeos: {
      get() {
        return this.filters.shipTo;
      },
      set(v) {
        this.filters.shipTo = v;
        this.fetchTestDeck(this.testRunId, {
          ...this.queryArgs,
          page: 0,
          shipTo: v,
        });
      },
    },
    selectedDocumentTypes: {
      get() {
        return this.filters.documentTypes;
      },
      set(v) {
        this.filters.documentTypes = v;
        this.fetchTestDeck(this.testRunId, {
          ...this.queryArgs,
          page: 0,
          documentTypes: v.map((o) => o.value || o),
        });
      },
    },
    selectedCurrencyCodes: {
      get() {
        return this.filters.currencyCodes;
      },
      set(v) {
        this.filters.currencyCodes = v;
        this.fetchTestDeck(this.testRunId, {
          ...this.queryArgs,
          page: 0,
          currencyCodes: v.map((o) => o.value || o),
        });
      },
    },
    documentTypesOptions() {
      return this.documentTypes || [];
    },
    currencyCodesOptions() {
      return (this.currencyCodes || []).map((c) => {
        return {
          label: c,
          value: c,
        };
      });
    },
    fields() {
      const fieldSet = new Set(this.transactionFields);
      fieldSet.add('recordStatus');

      return AVA_DOC_TABLE_COLS.map((field) => {
        field.label = field.label.replace('DOC ', '');
        return {
          ...field,
          hidden: !fieldSet.has(field.key),
          sorter: field.sortable,
        };
      }).filter((field) => {
        return !field.hidden;
      });
    },
    pageNumber: {
      get() {
        return this.page;
      },
      set(v) {
        if (this.isLoading) {
          return;
        }

        let pageNumber = v;
        if (!v || v < 1) {
          pageNumber = 1;
        }

        if (pageNumber !== this.page) {
          this.fetchTestDeck(this.testRunId, {
            ...this.queryArgs,
            page: pageNumber - 1,
          });
        }

        this.page = v;
      },
    },
    pageSize: {
      get() {
        return this.size;
      },
      set(v) {
        let pageSize = v;
        if (!v || v < 5) {
          pageSize = 5;
        }

        if (this.size !== pageSize) {
          this.fetchTestDeck(this.testRunId, {
            ...this.queryArgs,
            size: pageSize,
            page: 0,
          });
        }
        this.size = v;
      },
    },
    queryArgs() {
      return {
        page: this.pageNumber - 1,
        size: this.pageSize,
        queryText: this.tableFilterValue?.trim(),
        sort: this.calculatedSort,
        shipTo: this.filters.shipTo || [],
        shipFrom: this.filters.shipFrom || [],
        documentTypes: (this.filters.documentTypes || []).map(
          (o) => o.value || o,
        ),
        currencyCodes: (this.filters.currencyCodes || []).map(
          (o) => o.value || o,
        ),
        geoLogic: this.geoLogic,
        recordStatus: (this.filters.recordStatus || []).map(
          (o) => o.value || o,
        ),
      };
    },
    filterLabels() {
      const tags = [];
      if (this.filters.shipTo && this.filters.shipTo.length > 0) {
        tags.push({
          label: 'Ship To',
          labelSubText: `(${this.filters.shipTo.length})`,
          color: 'info',
          filterKey: 'shipTo',
        });
      }
      if (this.filters.shipFrom && this.filters.shipFrom.length > 0) {
        tags.push({
          label: `Ship From`,
          labelSubText: `(${this.filters.shipFrom.length})`,
          color: 'info',
          filterKey: 'shipFrom',
        });
      }

      if (this.filters.recordStatus && this.filters.recordStatus.length > 0) {
        tags.push({
          label: 'Status',
          labelSubText: `(${this.filters.recordStatus.length})`,
          color: 'info',
          filterKey: 'recordStatus',
        });
      }

      if (this.filters.currencyCodes && this.filters.currencyCodes.length > 0) {
        tags.push({
          label: 'Currency Code',
          labelSubText: `(${this.filters.currencyCodes.length})`,
          color: 'info',
          filterKey: 'currencyCodes',
        });
      }

      if (this.filters.documentTypes && this.filters.documentTypes.length > 0) {
        tags.push({
          label: 'Document Type',
          labelSubText: `(${this.filters.documentTypes.length})`,
          color: 'info',
          filterKey: 'documentTypes',
        });
      }
      return tags;
    },
    selectedRecordStatus: {
      get() {
        return this.filters.recordStatus;
      },
      set(v) {
        if (v) {
          if (Array.isArray(v)) {
            this.filters.recordStatus = v;
          } else {
            this.filters.recordStatus = [v];
          }
        } else {
          this.filters.recordStatus = null;
        }
      },
    },
    tableFilterValue: {
      get() {
        return this.internalTableFilterValue;
      },
      set(v) {
        this.internalTableFilterValue = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
          queryText: v?.trim(),
        };
        this.fetchTestDeck(this.testRunId, qa);
      },
    },
    calculatedSort() {
      const column = this.columnSorter.column;
      return `${column} ${this.columnSorter.asc ? 'ASC' : 'DESC'}`;
    },
    columnSorter: {
      get() {
        return this.internalSorter;
      },
      set(v) {
        let sort;
        if (!v) {
          sort = {
            external: true,
            resetable: false,
            column: 'document.code',
            asc: true,
          };
        } else {
          let column = v.column || 'document.code';
          let asc = v.asc === false ? false : true;

          sort = {
            external: true,
            resetable: false,
            column,
            asc,
          };
        }

        this.internalSorter = sort;

        const qa = {
          ...this.queryArgs,
          page: 0,
          sort: `${sort.column} ${sort.asc ? 'ASC' : 'DESC'}`,
        };

        this.fetchTestDeck(this.testRunId, qa);
      },
    },
  },
  watch: {
    testRunId(v) {
      this.fetchTestDeck(v, this.queryArgs);
    },
    showMap(v) {
      if (v && !this.$svgMap) {
        this.$nextTick(() => {
          this.$svgMap = createSvgMap(this.mapCountries);
          this.generateClickablePaths();
        });
      }
    },
  },
  mounted() {
    const self = this;
    this.fetchTestDeck(this.testRunId, this.queryArgs).then((data) => {
      self.$log.debug('Data', data);
      const countries =
        data.metrics?.countries ||
        mapCountryDataForSvg(
          data.metrics?.shipTo || {},
          data.metrics?.shipFrom || {},
        );

      self.mapCountries = Object.freeze(countries);
      if (self.showMap && !self.$svgMap) {
        self.$svgMap = createSvgMap(countries);
        this.generateClickablePaths();
      }
    });
  },
  methods: {
    clearMapToolTips() {
      this.$nextTick(() => {
        Array.from(document.getElementsByClassName('svgMap-tooltip')).forEach(
          (elm) => {
            elm.classList.remove('svgMap-active');
          },
        );
      });
    },
    formatMessage(key, value) {
      if (value) {
        return this.$t(`${key}.${value}`);
      } else {
        return this.$t(key);
      }
    },
    setRecordStatusFilter(filterValues) {
      var rsFilter = null;
      if (!filterValues || filterValues.length < 1) {
        this.selectedRecordStatus = null;
      } else {
        this.selectedRecordStatus = filterValues;
        if (Array.isArray(filterValues)) {
          rsFilter = filterValues;
        } else {
          rsFilter = [filterValues];
        }
      }
      this.fetchTestDeck(this.testRunId, {
        ...this.queryArgs,
        page: 0,
        recordStatus: rsFilter,
      });
    },
    toggleGeoLogic() {
      const logic = this.geoLogic === 'AND' ? 'OR' : 'AND';
      this.geoLogic = logic;
      if (
        this.selectedShipToGeos?.length > 0 ||
        this.selectedShipFromGeos?.length > 0
      ) {
        this.fetchTestDeck(this.testRunId, {
          ...this.queryArgs,
          geoLogic: logic,
          page: 0,
        });
      }
    },
    removeFilter(filterKey) {
      switch (filterKey) {
        case 'shipTo':
          this.selectedShipToGeos = [];
          break;
        case 'shipFrom':
          this.selectedShipFromGeos = [];
          break;
        case 'recordStatus':
          this.setRecordStatusFilter(null);
          break;
        case 'currencyCodes':
          this.selectedCurrencyCodes = [];
          break;
        case 'documentTypes':
          this.selectedDocumentTypes = [];
          break;
      }
    },
    handleCountryClick(isoCode) {
      let shipTo = [];
      let shipFrom = [];

      const shipToGeos = this.shipToGeos.filter(
        (g) =>
          g.key === isoCode ||
          g.countryKey === isoCode ||
          g.parentKey === isoCode,
      );

      if (shipToGeos.length > 1) {
        shipTo = shipToGeos.filter((g) => g.key !== isoCode).map((g) => g.key);
      } else {
        shipTo = shipToGeos.map((g) => g.key);
      }

      const shipFromGeos = this.shipFromGeos.filter(
        (g) =>
          g.key === isoCode ||
          g.countryKey === isoCode ||
          g.parentKey === isoCode,
      );

      if (shipFromGeos.length > 1) {
        shipFrom = shipFromGeos
          .filter((g) => g.key !== isoCode)
          .map((g) => g.key);
      } else {
        shipFrom = shipFromGeos.map((g) => g.key);
      }

      this.filters.shipTo = shipTo;
      this.filters.shipFrom = shipFrom;
      this.geoLogic = 'OR';

      this.fetchTestDeck(this.testRunId, {
        ...this.queryArgs,
        geoLogic: 'OR',
        shipTo,
        shipFrom,
      });
    },
    generateClickablePaths() {
      const self = this;
      this.$nextTick(() => {
        const svgMap = document.getElementById('test-deck-map');
        const countryPaths = svgMap.getElementsByClassName('svgMap-country');

        for (let country of countryPaths) {
          const isoCode = country.getAttribute('data-id');
          if (self.mapCountries[isoCode]) {
            country.addEventListener('click', () => {
              self.handleCountryClick(isoCode);
            });
            country.classList.add('clickable');
          }
        }
      });
    },
    doshowDocumentDetails(item) {
      this.selectedDocument = item._value;
      this.showDocumentDetails = true;
    },
    fetchTestDeck(id, queryArgs) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      const self = this;
      return new Promise((resolve, reject) => {
        api.testing
          .getTestRunResults(id, queryArgs)
          .then((res) => {
            const testRun = {
              itemCount: res.totalTransactionCount,
              invalidCount: res.invalidTransactionCount,
              errorCount: res.errorTransactionCount,
              validCount: res.testRun.validTransactionCount,
              transactionDate: res.testRun.transactionDate,
              testDeck: {
                id: res.testRun.transactionGroupId,
                name: res.testRun.transactionGroupName,
              },
              connectivity: {
                id: res.testRun.connectivityId,
                name: res.testRun.connectivityName,
              },
              status: res.testRun.runStatus,
              scheduledAt: format(
                parseISO(`${res.testRun.scheduledAt}Z`),
                'MMM dd, yyyy HH:mm',
              ),
              startedAt: format(
                parseISO(`${res.testRun.startedAt}Z`),
                'MMM dd, yyyy HH:mm',
              ),
              completedAt: format(
                parseISO(`${res.testRun.completedAt}Z`),
                'MMM dd, yyyy HH:mm',
              ),
            };
            testRun.validPercent =
              (testRun.validCount / testRun.itemCount) * 100;

            this.testRun = Object.freeze(testRun);
            self.taxEngineType = res.testRun.taxEngineType;

            const docResponseFields = {};
            const content = res.content.map((o) => {
              const trx = mapDocument(o, docResponseFields, self.formatMessage);
              return Object.freeze(trx);
            });

            const shipToGeos = res.filterOptions?.shipTo || [];
            const shipFromGeos = res.filterOptions?.shipFrom || [];
            const documentTypes = res.filterOptions?.documentTypes || [];
            const currencyCodes = res.filterOptions?.currencyCodes || [];
            self.content = content;
            self.shipToGeos = shipToGeos;
            self.shipFromGeos = shipFromGeos;
            self.currencyCodes = currencyCodes;
            self.documentTypes = documentTypes.map((type) => {
              return {
                label: this.$t(`avaDocType.${type}`),
                value: type,
              };
            });

            const addressGroups = new Set();
            const transactionFields = (
              res.testRun.transactionFields || []
            ).reduce((list, f) => {
              const field = getFieldForProperty(f);
              if (field) {
                if (field.isAddress) {
                  if (!addressGroups.has(field.escaped)) {
                    addressGroups.add(field.escaped);
                    list.push(field.escaped);
                  }
                } else {
                  list.push(field.escaped);
                }
              }
              return list;
            }, []);

            Object.keys(docResponseFields).forEach((key) => {
              transactionFields.push(key);
            });

            self.transactionFields = Object.freeze(transactionFields);
            self.totalPages = res.totalPages;
            self.totalCount = res.totalElements;
            self.page = (res.pageable?.pageNumber || 0) + 1;

            resolve({
              testRun: res.testRun,
              content,
              metrics: res.testRun?.metrics || {},
            });
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.$nextTick(() => {
              this.isLoading = false;
            });
          });
      });
    },
  },
};
</script>
