<template>
  <CModal
    class="vtx-invoice-results-details-modal modal-xxl"
    :show.sync="modalShow"
    size="xl"
    centered
    :close-on-backdrop="false"
  >
    <template #header>
      <div class="d-flex w-100">
        <h2>{{ documentType }}: {{ documentNumber }}</h2>
        <MRecordStatusBadge
          :value="recordStatus"
          class="h2"
          style="margin-left: auto; margin-right: 0"
        />
      </div>
    </template>
    <CCard class="document-details-card vtx-document-details-card">
      <CCardBody>
        <CRow>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Company</small><br />
              <strong class="h4">{{ companyCode }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout :color="!!divisionCode ? 'primary' : 'secondary'">
              <small class="text-muted">Division</small><br />
              <strong class="h4">{{ divisionCode || '--' }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout :color="!!departmentCode ? 'primary' : 'secondary'">
              <small class="text-muted">Department</small><br />
              <strong class="h4">{{ departmentCode || '--' }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="info">
              <small class="text-muted">Taxpayer Role</small><br />
              <strong class="h4">{{
                $tc(`companyRole.${taxpayerRole}`)
              }}</strong>
            </CCallout>
          </CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol col="4">
            <fieldset class="d-inline-flex">
              <legend>General</legend>
              <LabeledValue label="Invoice Date">
                {{ documentDate }}
              </LabeledValue>
              <LabeledValue label="Currency Code">
                {{ currencyCode }}
              </LabeledValue>
              <LabeledValue label="Transaction Type">
                {{ $tc(`transactionType.${transactionType}`) }}
              </LabeledValue>
            </fieldset>
          </CCol>
          <CCol>
            <fieldset class="d-inline-flex">
              <legend>Taxpayer Registrations</legend>
              <template v-if="registrations.length > 0">
                <LabeledValue
                  v-for="(reg, idx) in registrations"
                  :key="`${idx}_${reg.type}`"
                  :label="`${$tc('counterParty.' + reg.type)}`"
                >
                  {{ registration }}
                </LabeledValue>
              </template>
              <div v-else class="h5 text-muted mt-2 ml-1">None</div>
            </fieldset>
          </CCol>
          <CCol>
            <VtxCounterPartyBlock v-bind="counterparty" />
          </CCol>
        </CRow>
        <CTabs :active-tab.sync="activeTab" class="pt-5">
          <CTab title="Invoice" class="invoice-details-tab">
            <CCard class="no-border">
              <CCardBody>
                <CRow class="pb-4">
                  <CCol>
                    <CRow>
                      <CCol><h4>Invoice Level Validations</h4></CCol>
                    </CRow>
                    <CRow v-if="hasInvoiceValidations">
                      <CCol v-if="requestStatusValidation" col="12">
                        <VtxValidationResult :value="requestStatusValidation" />
                      </CCol>
                      <CCol
                        v-for="(v, vIdx) in filteredValidationResults"
                        :key="`${vIdx}_${v.name}`"
                        col="4"
                      >
                        <VtxValidationResult :value="v" />
                      </CCol>
                    </CRow>
                    <CRow v-else>
                      <CCol>
                        <div class="text-muted h4 mt-2 ml-1">
                          None <small class="ml-1">(see lines)</small>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div class="w-100 d-flex">
                      <span class="h4">Document Input Properties</span>
                      <MCollapseToggler :value.sync="showInvoiceInput" />
                    </div>
                    <hr />
                  </CCol>
                </CRow>
                <CCollapse :show="showInvoiceInput">
                  <CRow>
                    <CCol>
                      <CCard>
                        <CCardBody>
                          <CRow v-if="hasDocumentAddresses">
                            <CCol>
                              <fieldset>
                                <legend>
                                  Addresses
                                  <small class="text-muted">
                                    ({{ documentAddresses.length }})
                                  </small>
                                </legend>
                                <CRow>
                                  <CCol
                                    v-for="invAddress in documentAddresses"
                                    :key="invAddress.key"
                                    col="6"
                                    md="4"
                                    lg="3"
                                  >
                                    <DocumentAddress
                                      :value="invAddress"
                                      :label="
                                        $t(
                                          `addressType.${invAddress.fieldName}`,
                                        )
                                      "
                                      class="pt-2"
                                    />
                                  </CCol>
                                </CRow>
                              </fieldset>
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </CCollapse>
              </CCardBody>
            </CCard>
          </CTab>
          <CTab :title="`Lines (${lines.length})`" class="line-details-tab">
            <CTabs
              :active-tab="activeLineTab"
              class="pt-4"
              variant="pills"
              :vertical="{
                navs: 'col-xs-2 col-md-1',
                content: 'col-xs-10 col-md-11',
              }"
            >
              <CTab
                v-for="(line, index) in lines"
                :key="`${index}_${line.lineNumber}_${line.key}`"
                :title="`Line ${line.lineNumber}`"
              >
                <VtxInvoiceLineResultTab :line="line" />
              </CTab>
            </CTabs>
          </CTab>
          <CTab title="XML" class="xml-tab">
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol col="6" style="position: relative">
                    <h4>Request XML</h4>
                    <MClipboardButton
                      id="btn-vtx-invoice-details-request-copy"
                      style="
                        margin-right: 1rem;
                        position: absolute;
                        top: -5px;
                        right: 25px;
                      "
                      @click="copyTextToClipBoard"
                    />
                    <pre
                      :id="xmlDetailsId"
                      class="code hljs xml"
                      v-html="documentXML.highlight"
                    ></pre>
                  </CCol>
                  <CCol col="6" style="position: relative">
                    <h4>Response XML</h4>
                    <MClipboardButton
                      id="btn-vtx-invoice-details-response-copy"
                      style="
                        margin-right: 1rem;
                        position: absolute;
                        top: -5px;
                        right: 25px;
                      "
                      @click="copyResponseToClipBoard"
                    />
                    <pre
                      :id="xmlResponseDetailsId"
                      class="code hljs xml"
                      v-html="responseXML.highlight"
                    ></pre>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
  </CModal>
</template>
<script>
import {
  parseTransactionTypeValue,
  parseTaxpayerRole,
  parseDocCounterParty,
  parseDocRegistrations,
  parseDocAttributes,
  parseDocumentXML,
  parseResponseXML,
} from '@/utils/parsing/vtx/vtxParsing';
import { VtxCounterPartyBlock } from '@/components/TestDeck';
import VtxValidationResult from '../VtxValidationResult';
import DocumentAddress from '@/components/DocumentAddress';
import MRecordStatusBadge from '@/components/MRecordStatusBadge';
import VtxInvoiceLineResultTab from './VtxInvoiceLineResultTab';
import { MClipboardButton } from '@/components/Buttons';
export default {
  name: 'VtxInvoiceResultsDetailsModal',
  components: {
    VtxCounterPartyBlock,
    DocumentAddress,
    MRecordStatusBadge,
    VtxValidationResult,
    VtxInvoiceLineResultTab,
    MClipboardButton,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInvoiceInput: false,
      activeTab: 0,
      activeLineTab: 0,
      ...this.parseValue(this.value),
    };
  },
  computed: {
    xmlDetailsId() {
      return `vtx-xml-details_${this.value.id}`;
    },
    xmlResponseDetailsId() {
      return `vtx-xml-response-details_${this.value.id}`;
    },
    requestStatusValidation() {
      return this.validationResults.find((v) => v.name === 'REQUEST STATUS');
    },
    filteredValidationResults() {
      return this.validationResults.filter((v) => v.name !== 'REQUEST STATUS');
    },
    modalShow: {
      get() {
        return this.show === true;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
    documentAddresses() {
      return [
        'DOC_DEST',
        'DOC_PHYS_ORIGIN',
        'DOC_ADMIN_DEST',
        'DOC_ADMIN_ORIGIN',
      ]
        .map((key) => {
          if (this[key]?.empty === false) {
            return this[key];
          } else {
            return null;
          }
        })
        .filter((a) => {
          return !!a;
        });
    },
  },
  methods: {
    parseValue(v) {
      const transactionType = parseTransactionTypeValue(v);
      const taxpayerRole = parseTaxpayerRole(transactionType);

      let doc = {
        ...v,
        taxpayerRole,
        transactionType,
        counterparty: parseDocCounterParty(v),
        registrations: parseDocRegistrations(v),
        documentAttributes: parseDocAttributes(v),
        documentXML: Object.freeze(parseDocumentXML(v.documentXML)),
        responseXML: v.responseXML
          ? Object.freeze(parseResponseXML(v.responseXML))
          : { raw: null, highlight: 'No response' },
      };

      return doc;
    },
    copyTextToClipBoard() {
      const copyText = document.createElement('textarea');
      const parent = document.getElementById(this.xmlDetailsId);
      copyText.value = this.documentXML.plain;
      copyText.setAttribute('readonly', 'true');
      copyText.style = { visibility: 'hidden' };
      parent.appendChild(copyText);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
      parent.removeChild(copyText);
    },
    copyResponseToClipBoard() {
      if (this.responseXML && this.responseXML.plain) {
        const copyText = document.createElement('textarea');
        const parent = document.getElementById(this.xmlResponseDetailsId);
        copyText.value = this.responseXML.plain;
        copyText.setAttribute('readonly', 'true');
        copyText.style = { visibility: 'hidden' };
        parent.appendChild(copyText);
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
        parent.removeChild(copyText);
      }
    },
  },
};
</script>
