<template>
  <CCard
    class="validation-result vtx-validation-result"
    :class="{ 'is-valid': value.isValid, 'is-invalid': !value.isValid }"
  >
    <CCardHeader>
      <div><CIcon :name="iconName" :class="iconClasses" size="xl" /></div>
      <div class="text-center">{{ displayLabel }}</div>
    </CCardHeader>
    <CCardBody v-if="showExpectedValue" class="d-flex text-center">
      <div class="validation-value-wrapper">
        <div class="labeled-value-value expected-value">
          {{ expectedValue }}
        </div>
        <label class="text-muted text-small labeled-value-label"
          >Expected</label
        >
      </div>
      <div class="validation-value-wrapper">
        <div
          class="labeled-value-value actual-value"
          :class="{ 'text-danger': !value.isValid }"
        >
          {{ actualValue }}
        </div>
        <label
          class="text-small labeled-value-label"
          :class="{
            'text-danger': !value.isValid,
            'text-muted': value.isValid,
          }"
          >Actual</label
        >
      </div>
    </CCardBody>
    <CCardBody v-else class="d-flex text-center">
      <div class="w-100" v-html="validationMessage"></div>
    </CCardBody>
  </CCard>
</template>
<script>
import {
  VTX_TRANSACTION_FIELDS_MAP,
  convertPropertyName,
} from '@/utils/vtxFieldUtils';

export default {
  name: 'VtxValidationResult',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    validationMessage() {
      if (!this.value || !this.value.messages) {
        return null;
      }

      return this.value.messages[0]
        .replaceAll('. ', '.<br />')
        .split('<br />')
        .map((line) => {
          return `<p>${line.trim()}</p>`;
        })
        .reduce((prv, line) => {
          return `${prv}${line}`;
        }, '');
    },
    showExpectedValue() {
      return this.value.name !== 'REQUEST STATUS';
    },
    statusColor() {
      if (this.value.isValid) {
        return 'success';
      } else {
        return 'danger';
      }
    },
    displayLabel() {
      let field =
        VTX_TRANSACTION_FIELDS_MAP[convertPropertyName(this.value.name)];
      if (field) {
        return field.label.replace('Expected', '').replace('Exp.', '');
      } else {
        return this.value.name;
      }
    },
    expectedValue() {
      return this.value.expectedValue;
    },
    actualValue() {
      return this.value.actualValue;
    },
    iconName() {
      if (this.value.isValid) {
        return 'cis-check-circle';
      } else {
        return 'cis-exclamation-circle';
      }
    },
    iconClasses() {
      if (this.value.isValid) {
        return 'text-success';
      } else {
        return 'text-danger';
      }
    },
  },
};
</script>
<style lang="scss">
.validation-result {
  margin-right: 1rem;
  padding: 5px 20px;
  border: 1px solid #d8dbe0 !important;
  margin-top: 1rem;

  .card-header {
    text-align: center;
    font-weight: bold;

    .c-icon {
      margin-bottom: 5px;
    }
  }
  .card-body {
    position: relative;
    padding: 0.25rem 10px;
    justify-content: space-between;
  }

  .validation-value-wrapper {
    padding: 0 0.5rem;
    margin: 0 auto;
  }

  .c-vr {
    display: block;
    width: 1px;
    background-color: rgba(0, 0, 21, 0.2);
  }

  p {
    margin-bottom: 0.25rem;
  }
}
</style>
