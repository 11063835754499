<template>
  <CRow :id="lineKey" class="line-detail-row sbx-line-detail-row">
    <CCol col="1" class="line-number-col">
      <div class="text-uppercase text-muted small">Line</div>
      <div class="text-value-lg">
        {{ lineNumber
        }}<CBadge
          v-if="lineStatus !== 'VALID'"
          :color="statusLabel.color"
          class="ml-2"
          >{{ statusLabel.text }}</CBadge
        >
      </div>
    </CCol>

    <CCol
      col="3"
      class="expectations-col"
      :class="{ 'empty-col': !hasResults }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasResults,
          'c-callout-success': lineStatus === 'VALID',
          'c-callout-danger': lineStatus !== 'VALID',
        }"
      >
        Results
      </div>
      <div v-if="hasResults" class="row">
        <CCol>
          <table class="validation-result-table dt-density-dense">
            <tbody>
              <tr
                v-for="(result, idx) in validationResults"
                :key="`${lineKey}_${result.name.replace('.', '_')}_${idx}`"
                class="validation-result-row"
              >
                <td class="label">{{ result.label }}</td>
                <td>
                  <CBadge
                    v-if="!result.isValid"
                    :color="
                      result.isValid
                        ? 'success'
                        : !result.isChecked
                        ? 'warning'
                        : 'danger'
                    "
                  >
                    {{
                      result.isValid
                        ? 'PASS'
                        : !result.isChecked
                        ? 'SKIPPED'
                        : 'FAIL'
                    }}
                  </CBadge>
                </td>
                <td>
                  <div class="d-flex">
                    <LabeledValue label="Expected">
                      {{ result.expectedValue }}
                    </LabeledValue>
                    <LabeledValue v-if="result.actualValue" label="Actual">
                      {{ result.actualValue }}
                    </LabeledValue>
                    <LabeledValue v-else label="Actual">
                      <span class="text-small">{{ result.message }}</span>
                    </LabeledValue>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </div>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
    <CCol col="2" style="min-width: 355px">
      <div class="col-label c-callout c-callout-primary">Tax Summary</div>
      <dl class="row">
        <dt class="col-5">Taxable Basis</dt>
        <dd class="col-7">
          {{
            taxSummary.taxableBasis > 0
              ? $format.currency(currencyCode, taxSummary.taxableBasis, 2)
              : '--'
          }}
        </dd>
        <dt class="col-5">Non-Taxable Basis</dt>
        <dd class="col-7">
          {{
            taxSummary.nonTaxableBasis > 0
              ? $format.currency(currencyCode, taxSummary.nonTaxableBasis, 2)
              : '--'
          }}
        </dd>
        <dt class="col-5">Exempt Amount</dt>
        <dd class="col-7">
          {{
            taxSummary.exemptAmount > 0
              ? $format.currency(currencyCode, taxSummary.exemptAmount, 2)
              : '--'
          }}
        </dd>
        <dt class="col-5">Tax Rate</dt>
        <dd class="col-7">
          {{
            taxSummary.taxRate > 0
              ? $format.percent(taxSummary.taxRate, 4)
              : '--'
          }}
        </dd>
        <dt class="col-5">Effective Tax Rate</dt>
        <dd class="col-7">
          {{
            taxSummary.effectiveTaxRate > 0
              ? $format.percent(taxSummary.effectiveTaxRate, 4)
              : '--'
          }}
        </dd>
        <dt v-if="taxSummary.accrualAmount > 0" class="col-5">
          Accrual Amount
        </dt>
        <dd v-if="taxSummary.accrualAmount > 0" class="col-7">
          {{
            taxSummary.accrualAmount > 0
              ? $format.currency(currencyCode, taxSummary.accrualAmount, 2)
              : '--'
          }}
        </dd>
        <!-- <dt v-if="taxSummary.payToVendorAmount > 0" class="col-5">
          Pay to Vendor Amount
        </dt>
        <dd v-if="taxSummary.payToVendorAmount > 0" class="col-7">
          {{
            taxSummary.payToVendorAmount > 0
              ? $format.currency(currencyCode, taxSummary.payToVendorAmount, 2)
              : '--'
          }}
        </dd> -->
        <!-- <dt v-if="taxSummary.vctEffectiveTaxableBasis > 0" class="col-5">
          Accrual Amount
        </dt>
        <dd v-if="taxSummary.vctEffectiveTaxableBasis > 0" class="col-7">
          {{
            taxSummary.vctEffectiveTaxableBasis > 0
              ? $format.currency(
                  currencyCode,
                  taxSummary.vctEffectiveTaxableBasis,
                  2,
                )
              : '--'
          }}
        </dd> -->
      </dl>
    </CCol>
    <CCol v-if="hasVCT" col="2" class="vct-col">
      <div class="col-label c-callout c-callout-primary">
        Vendor Charged Tax
      </div>
      <dl class="row">
        <dt class="col-5">Tax Variance</dt>
        <dd class="col-7">{{ vct.variance || '--' }}</dd>
        <dt class="col-5">Eff. Basis</dt>
        <dd class="col-7">{{ vct.vctEffectiveTaxableBasis || '--' }}</dd>
        <dt class="col-5">Pay To Amount</dt>
        <dd class="col-7">{{ vct.payToAmount || '--' }}</dd>
      </dl>
    </CCol>
    <CCol col="2" class="product-col">
      <div class="col-label c-callout c-callout-primary">Product</div>
      <dl class="row product-list">
        <template v-if="hasProduct">
          <template v-for="(product, index) in products">
            <dt :key="`${lineKey}_${product.type}_${index}_dt`" class="col-5">
              {{ product.type }}:
            </dt>
            <dd :key="`${lineKey}_${product.type}_${index}_dd`" class="col-7">
              <pre>{{ product.code }}</pre>
            </dd>
          </template>
        </template>
        <dt class="col-5">Quantity:</dt>
        <dd class="col-7">
          <pre>{{ quantity || '--' }}</pre>
        </dd>
        <dt class="col-5">Gross:</dt>
        <dd class="col-7">
          <pre>{{ grossAmount || '--' }}</pre>
        </dd>
      </dl>
    </CCol>
  </CRow>
</template>
<script>
import { SBX_LINE_FIELDS_MAP } from '@/utils/sbxFieldUtils';

export default {
  name: 'SbxInvoiceLineResult',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return this.mapLine(this.value);
  },
  computed: {
    hasVCT() {
      return this?.vct?.hasVCT === true;
    },
    vct() {
      const variance =
        this.taxSummary?.taxVariance || this.value?.taxSummary?.taxVariance;
      const effectiveBasis =
        this.taxSummary?.vctEffectiveTaxableBasis ||
        this.value?.taxSummary?.vctEffectiveTaxableBasis;
      const payToAmount =
        this.taxSummary?.payToVendorAmount ||
        this.value?.taxSummary?.payToVendorAmount;

      const currency = this.currencyCode || this.value?.currencyCode || 'USD';

      if (!variance && !effectiveBasis && !payToAmount) {
        return {
          hasVCT: false,
        };
      } else {
        return {
          hasVCT: true,
          variance: this.$format.currency(currency, variance),
          effectiveBasis: this.$format.currency(currency, effectiveBasis),
          payToAmount: this.$format.currency(currency, payToAmount),
        };
      }
    },
  },
  methods: {
    mapLine(line) {
      const data = {
        lineKey: line.key,
        lineNumber: line.lineNumber,
        quantity: this.$format.number(line.quantity),
        grossAmount: line.grossAmount,
        currencyCode: line.currencyCode || 'USD',
        lineStatus: line.lineStatus?.toUpperCase() || 'ERROR',
        taxSummary: line.taxSummary || {},
        products: [],
        hasProduct: false,
        addresses: line.addresses,
        validationResults: [],
      };

      data.validationResults = (line.validationResults || []).map((e) => {
        const label = SBX_LINE_FIELDS_MAP[e.name]?.label || e.name;
        let result = {
          ...e,
          label: label.replace('Exp. ', '').replace('Act. ').trim(),
        };

        return result;
      });

      switch (data.lineStatus) {
        case 'VALID':
          data.statusLabel = {
            color: 'success',
            text: 'PASS',
          };
          break;
        case 'INVALID':
          data.statusLabel = {
            color: 'danger',
            text: 'FAIL',
          };
          break;
        default:
          data.statusLabel = {
            color: 'warning',
            text: 'ERROR',
          };
          break;
      }
      if (line.products && line.products.length > 0) {
        data.products = line.products.map((p) => {
          return {
            type: this.$t(`productType.${p.type}`),
            code: p.code,
          };
        });

        data.hasProduct = true;
      }

      data.customAttributes = [
        ...(line.customAttributes?.userElement || []),
        ...(line.customAttributes?.amount || []),
        ...(line.customAttributes?.date || []),
      ];

      data.hasAddresses = (data.addresses || []).length > 0;
      data.hasCustomAttributes = (data.customAttributes || []).length > 0;
      data.hasResults = data.validationResults.length > 0;

      return Object.freeze(data);
    },
  },
};
</script>
<style lang="scss">
.validation-result-table {
  background-color: #fff !important;
  tr,
  td,
  th {
    background-color: #fff !important;
    border: none;
  }
  .validation-result-row {
    .label {
      font-weight: bold;
    }
  }
}

/*
.validation-result-entry {
  border: none;
  display: inline-flex;
  width: 100%;
  .validation-result-label {
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 0.5rem;
    label,
    .label {
      width: 100%;
      font-weight: bold;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .validation-result-value-wrapper {
    width: 100%;
    display: inline-flex;
  }
}
*/
</style>
