<template>
  <CRow :id="lineKey" class="line-detail-row vtx-line-detail-row">
    <CCol col="1" class="line-number-col">
      <div class="text-uppercase text-muted small">Line</div>
      <div class="text-value-lg">
        {{ lineNumber
        }}<CBadge :color="statusLabel.color" class="ml-2">{{
          statusLabel.text
        }}</CBadge>
      </div>
    </CCol>
    <CCol
      col="3"
      class="expectations-col"
      :class="{ 'empty-col': !hasResults }"
    >
      <div
        class="col-label c-callout"
        :class="{
          'text-muted': !hasResults,
          'c-callout-success': lineStatus === 'VALID',
          'c-callout-danger': lineStatus !== 'VALID',
        }"
      >
        Results
      </div>
      <div v-if="hasResults" class="row">
        <CCol>
          <table class="validation-result-table dt-density-dense">
            <tbody>
              <tr
                v-for="result in validationResults"
                :key="`${lineKey}_${result.name}`"
                class="validation-result-row"
              >
                <td class="label">{{ result.label }}</td>
                <td>
                  <CBadge
                    v-if="!result.isValid"
                    :color="result.isValid ? 'success' : 'danger'"
                  >
                    {{ result.isValid ? 'PASS' : 'FAIL' }}
                  </CBadge>
                </td>
                <td>
                  <div class="d-flex">
                    <LabeledValue label="Expected">
                      {{ result.expectedValue }}
                    </LabeledValue>
                    <LabeledValue label="Actual">
                      {{ result.actualValue }}
                    </LabeledValue>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </div>
      <div v-else class="empty-col-section text-muted">None</div>
    </CCol>
    <CCol col="3">
      <div class="col-label c-callout c-callout-primary">Tax Summary</div>
      <dl class="row">
        <dt class="col-5">Taxable Basis</dt>
        <dd class="col-7">
          {{
            taxSummary.taxableBasis > 0
              ? $format.currency(currencyCode, taxSummary.taxableBasis, 2)
              : '--'
          }}
        </dd>
        <dt class="col-5">Non-Taxable Basis</dt>
        <dd class="col-7">
          {{
            taxSummary.nonTaxableBasis > 0
              ? $format.currency(currencyCode, taxSummary.nonTaxableBasis, 2)
              : '--'
          }}
        </dd>
        <dt class="col-5">Exempt Amount</dt>
        <dd class="col-7">
          {{
            taxSummary.exemptAmount > 0
              ? $format.currency(currencyCode, taxSummary.exemptAmount, 2)
              : '--'
          }}
        </dd>
        <dt class="col-5">Tax Rate</dt>
        <dd class="col-7">
          {{
            taxSummary.taxRate > 0
              ? $format.percent(taxSummary.taxRate, 2)
              : '--'
          }}
        </dd>
        <dt class="col-5">Effective Tax Rate</dt>
        <dd class="col-7">
          {{
            taxSummary.effectiveTaxRate > 0
              ? $format.percent(taxSummary.effectiveTaxRate, 2)
              : '--'
          }}
        </dd>
      </dl>
    </CCol>
    <CCol col="3" class="product-col">
      <div class="col-label c-callout c-callout-primary">Product</div>
      <dl class="row product-list">
        <template v-if="hasProduct">
          <template v-for="(product, index) in products">
            <dt :key="`${lineKey}_${product.type}_${index}_dt`" class="col-5">
              {{ product.type }}:
            </dt>
            <dd :key="`${lineKey}_${product.type}_${index}_dd`" class="col-7">
              <pre>{{ product.code }}</pre>
            </dd>
          </template>
        </template>
        <dt class="col-5">Quantity:</dt>
        <dd class="col-7">
          <pre>{{ quantity || '--' }}</pre>
        </dd>
        <dt class="col-5">Extended Price:</dt>
        <dd class="col-7">
          <pre>{{ grossAmount || '--' }}</pre>
        </dd>
      </dl>
    </CCol>
  </CRow>
</template>
<script>
export default {
  name: 'VtxInvoiceLineResult',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return this.mapLine(this.value);
  },
  methods: {
    mapLine(line) {
      const data = {
        ...line,
        lineKey: line.key,
      };

      delete data['key'];
      data.hasAddresses = line.addresses.length > 0;
      data.hasFlexFields = data.flexibleFields.empty === false;
      data.hasResults = data.validationResults.length > 0;
      data.hasProduct = !!data.product;

      return Object.freeze(data);
    },
  },
};
</script>
