<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol col="1">
          <MRecordStatusBadge
            class="h2"
            :value="line.lineStatus"
            style="margin-top: auto; margin-bottom: auto"
          />
        </CCol>
        <CCol>
          <div class="w-100 d-flex">
            <template v-if="line.products.length > 0">
              <LabeledValue
                v-for="(product, index) in line.products"
                :key="`${product.type}_${index}`"
                :label="$t(`productType.${product.type}`)"
              >
                <span class="h4">{{ product.code || '--' }}</span>
              </LabeledValue>
            </template>
            <LabeledValue label="Quantity">
              <span class="h4">{{ line.quantity || '--' }}</span>
            </LabeledValue>
            <LabeledValue label="Extended Price">
              <span class="h4">{{ line.grossAmount || '--' }}</span>
            </LabeledValue>
          </div>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Taxable Basis</small><br />
            <strong class="h4">{{
              $format.currency(
                line.currencyCode,
                line.taxSummary.taxableBasis,
              ) || '--'
            }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Total Tax Amount</small><br />
            <strong class="h4">{{
              $format.currency(line.currencyCode, line.totalTaxAmount) || '--'
            }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Tax Rate</small><br />
            <strong class="h4">{{
              $format.percent(line.taxSummary.taxRate, 4)
            }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">Eff. Tax Rate</small><br />
            <strong class="h4">{{
              $format.percent(line.taxSummary.effectiveTaxRate, 4)
            }}</strong>
          </CCallout>
        </CCol>
      </CRow>
      <CRow v-if="line.hasLineValidationResults">
        <CCol>
          <h4>Line Level Validations</h4>
        </CCol>
      </CRow>
      <CRow v-if="hasLineValidationResults" class="pb-4">
        <CCol
          v-for="(v, vIdx) in line.validationResults"
          :key="`${vIdx}_${v.name}`"
          col="6"
        >
          <VtxValidationResult :value="v" />
        </CCol>
      </CRow>
      <CRow v-if="lineTaxes.length > 0">
        <CCol>
          <div class="w-100 d-flex">
            <div>
              <span class="h4">
                Line Level Taxes
                <span class="text-muted">({{ lineTaxes.length }})</span>
              </span>
            </div>
            <MCollapseToggler :value.sync="showTaxDetails" />
          </div>
          <hr />
        </CCol>
      </CRow>
      <CCollapse v-if="lineTaxes.length > 0" :show="showTaxDetails">
        <CRow
          v-for="(lineTax, tIdx) in lineTaxes"
          :key="`${tIdx}_${lineTax.jurisdictionId}_${lineTax.situs}`"
        >
          <CCol>
            <VtxLineTaxResult
              :line="line"
              :line-tax="lineTax"
              :tax-index="tIdx + 1"
            />
          </CCol>
        </CRow>
      </CCollapse>
      <CRow v-if="hasLineAttributes || hasFlexFields" class="pt-4">
        <CCol>
          <div class="w-100 d-flex">
            <span class="h4">Line Input Properties</span>
            <MCollapseToggler :value.sync="showLineInput" />
          </div>
          <hr />
        </CCol>
      </CRow>
      <CCollapse :show="showLineInput">
        <CRow v-if="hasLineAddresses" class="pb-4">
          <CCol>
            <fieldset>
              <legend>Addresses</legend>
              <CRow class="pt-2">
                <CCol
                  v-for="(address, addressIndex) in lineAddresses"
                  :key="`${line.lineNumber}_${addressIndex}_${address.fieldName}`"
                  col="6"
                  md="4"
                >
                  <DocumentAddress
                    :value="address"
                    :label="$tc(`addressType.${address.addressType}`)"
                  />
                </CCol>
              </CRow>
            </fieldset>
          </CCol>
        </CRow>
        <CRow v-if="hasFlexFields" class="pb-4">
          <CCol>
            <fieldset>
              <legend>Flexible Code Fields</legend>
              <div
                v-if="line.flexibleFields.codeFields.length > 0"
                class="user-element-container"
              >
                <LabeledValue
                  v-for="(cFlex, cFlexIdx) in line.flexibleFields.codeFields"
                  :key="`CODE_${cFlexIdx}_${cFlex.fieldId}`"
                  :label="parseFlexFieldLabel(cFlex.fieldId)"
                  :value="cFlex.value"
                />
              </div>
              <div v-else class="text-muted h4 mt-2 ml-1">None</div>
            </fieldset>
          </CCol>
          <CCol>
            <fieldset>
              <legend>Flexible Number Fields</legend>
              <div
                v-if="line.flexibleFields.numberFields.length > 0"
                class="user-element-container"
              >
                <LabeledValue
                  v-for="(nFlex, nFlexIdx) in line.flexibleFields.numberFields"
                  :key="`NUMBER_${nFlexIdx}_${nFlex.fieldId}`"
                  :label="parseFlexFieldLabel(nFlex.fieldId)"
                  :value="nFlex.value"
                />
              </div>
              <div v-else class="text-muted h4 mt-2 ml-1">None</div>
            </fieldset>
          </CCol>
          <CCol>
            <fieldset>
              <legend>Flexible Date Fields</legend>
              <div
                v-if="line.flexibleFields.dateFields.length > 0"
                class="user-element-container"
              >
                <LabeledValue
                  v-for="(dFlex, dFlexIdx) in line.flexibleFields.dateFields"
                  :key="`DATE_${dFlexIdx}_${dFlex.fieldId}`"
                  :label="parseFlexFieldLabel(dFlex.fieldId)"
                  :value="dFlex.value.split('T')[0]"
                />
              </div>
              <div v-else class="text-muted h4 mt-2 ml-1">None</div>
            </fieldset>
          </CCol>
        </CRow>
        <CRow v-if="lineAttributes.length > 0" class="pb-4">
          <CCol>
            <fieldset>
              <legend>All Other Line Properties</legend>
              <div class="user-element-container">
                <LabeledValue
                  v-for="e in lineAttributes"
                  :key="e.key"
                  :label="e.label"
                >
                  <MCheckIcon
                    v-if="e.fieldType === 'BOOLEAN'"
                    :value="e.value"
                    show-false
                  />
                  <span v-else>
                    {{ e.value }}
                  </span>
                </LabeledValue>
              </div>
            </fieldset>
          </CCol>
        </CRow>
      </CCollapse>
    </CCardBody>
  </CCard>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
import VtxValidationResult from '../VtxValidationResult';
import MRecordStatusBadge from '@/components/MRecordStatusBadge';
import VtxLineTaxResult from './VtxLineTaxResult';
import {
  parseLineAttributes,
  parseLineAddresses,
  parseFlexFieldLabel,
} from '@/utils/parsing/vtx/vtxParsing';

export default {
  name: 'VtxInvoiceLineResultTab',
  components: {
    DocumentAddress,
    VtxValidationResult,
    MRecordStatusBadge,
    VtxLineTaxResult,
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showLineInput: false,
      showTaxDetails: false,
    };
  },
  computed: {
    lineTaxes() {
      return this.line.taxes || [];
    },
    lineAddresses() {
      return parseLineAddresses(this.line.addresses || []);
    },
    hasLineAddresses() {
      return this.lineAddresses.length > 0;
    },
    hasLineValidationResults() {
      return (this.line.validationResults || []).length > 0;
    },
    lineAttributes() {
      return parseLineAttributes(this.line.lineProperties || {});
    },
    hasLineAttributes() {
      return this.lineAttributes.length > 0;
    },
    hasFlexFields() {
      return (
        this.line.hasFlexFields ||
        this.line.flexibleFields?.codeFields?.length > 0 ||
        this.line.flexibleFields?.numberFields?.length > 0 ||
        this.line.flexibleFields?.dateFields?.length > 0
      );
    },
  },
  methods: {
    parseFlexFieldLabel(fieldId) {
      return parseFlexFieldLabel(fieldId);
    },
  },
};
</script>
