import svgMap from 'svgmap';
import {
  getFieldForProperty,
  AVA_FORMAT_FIELDS,
  AVA_FORMAT_FIELDS_KEYS,
} from '@/utils/ava/avaFieldUtils';
import { joinToString } from '@/utils/helpers';
import { formatCurrency } from '@/utils/format';

export function createSvgMap(countries) {
  return new svgMap({
    targetElementID: 'test-deck-map',
    colorMax: '#0D7930',
    colorMin: '#D6FAE2',
    noDataText: 'No relevant test data',
    flagURL: '/img/countries/svg/{0}.svg',
    data: {
      applyData: 'shipTo',

      data: {
        shipTo: {
          name: 'Ship To',
        },
        shipFrom: {
          name: 'Ship From',
        },
        total: {
          name: 'Total',
        },
      },
      values: countries,
    },
  });
}

function formatMulti(
  fmtKey,
  value,
  formatMessage = (i18nkey, value) => `${i18nkey}.${value}`,
) {
  const formatted = joinToString(
    splitExp(value).map((part, idx) => {
      let fmtPart = formatMessage(fmtKey, part);
      if (fmtPart === `${fmtKey}.${part}`) {
        fmtPart = part;
      }
      if (idx > 0) {
        return ` ${fmtPart}`;
      } else {
        return fmtPart;
      }
    }),
  );

  return `[${formatted.trim()}]`;
}

function isMultiExp(value) {
  if (value.startsWith('[') && value.endsWith(']')) {
    return true;
  } else if (value.includes(', ')) {
    return true;
  }

  return false;
}

function splitExp(value) {
  if (value.startsWith('[')) {
    return value
      .substring(1, value.length - 1)
      .split(',')
      .map((part) => {
        return part.trim();
      });
  } else {
    return value.split(',').map((part) => {
      return part.trim();
    });
  }
}
export function mapDocument(
  doc,
  responseFields = {},
  formatMessage = (i18nkey, value) => `${i18nkey}.${value}`,
) {
  const documentProperties = Object.keys(doc.documentProperties).reduce(
    (props, key) => {
      const field = getFieldForProperty(key);
      if (field && !field.isAddress) {
        if (field.fieldType != 'MONEY') {
          props[field.escaped] = doc.documentProperties[key];
        } else {
          props[field.escaped] = formatCurrency(
            doc.currencyCode,
            doc.documentProperties[key],
          );
        }

        if (field.isResponse && !responseFields[field.escaped]) {
          responseFields[field.escaped] = field;
        }
      }

      return props;
    },
    {},
  );

  doc.validationResults?.document?.forEach((result) => {
    const field = getFieldForProperty(result.name);
    if (field) {
      const fmt = field.i18n || AVA_FORMAT_FIELDS[field.name];
      responseFields[field.escaped] = field;

      if (fmt) {
        if (result.expectedValue) {
          if (isMultiExp(result.expectedValue)) {
            result.expectedValue = formatMulti(
              fmt,
              result.expectedValue,
              formatMessage,
            );
          } else {
            result.expectedValue = formatMessage(fmt, result.expectedValue);
          }
        }

        if (result.actualValue) {
          const actualValue = result.actualValue;
          if (isMultiExp(actualValue)) {
            result.actualValue = formatMulti(fmt, actualValue, formatMessage);
          } else {
            result.actualValue = formatMessage(fmt, result.actualValue);
          }
        }

        documentProperties[field.escaped] = result.expectedValue;
        const actualField = field.escaped.replace('EXPECTED_', 'ACTUAL_');
        documentProperties[actualField] = result.actualValue;
        responseFields[actualField] = getFieldForProperty(actualField);
      }
    }
  });
  Object.keys(doc.addresses).forEach((group) => {
    const field = getFieldForProperty(group);
    if (field) {
      documentProperties[field.escaped] = doc.addresses[group];
    }
  });

  const lines = doc.lines.map((line, idx) => {
    return mapLine(doc, idx, line, formatMessage);
  });

  switch (doc.recordStatus?.toUpperCase()) {
    case 'VALID':
      documentProperties.statusLabel = {
        color: 'success',
        text: 'PASS',
      };
      break;
    case 'INVALID':
      documentProperties.statusLabel = {
        color: 'danger',
        text: 'FAIL',
      };
      break;
    default:
      documentProperties.statusLabel = {
        color: 'warning',
        text: 'ERROR',
      };
  }

  documentProperties['DOCUMENT_CURRENCY_CODE'] =
    doc.currencyCode?.toUpperCase();
  documentProperties['DOCUMENT_TYPE'] = formatMessage(
    'avaDocType',
    doc.documentType?.toUpperCase(),
  );
  documentProperties['_lines'] = lines;
  documentProperties['_value'] = Object.freeze(doc);
  documentProperties['DOCUMENT_DATE'] = doc.documentDate;

  return documentProperties;
}

function mapLine(
  doc,
  idx,
  line,
  formatMessage = (i18nkey, value) => `${i18nkey}.${value}`,
) {
  const output = {
    ...line,
    currencyCode: doc.currencyCode,
    key: `${doc.id}_${idx}_${line.lineNumber}`,
  };

  AVA_FORMAT_FIELDS_KEYS.forEach((key) => {
    if (output[key]) {
      output[key] = formatMessage(AVA_FORMAT_FIELDS[key], output[key]);
    }
  });

  (output.validationResults || []).forEach((result) => {
    if (AVA_FORMAT_FIELDS[result.name]) {
      if (result.expectedValue) {
        if (isMultiExp(result.expectedValue)) {
          result.expectedValue = formatMulti(
            AVA_FORMAT_FIELDS[result.name],
            result.expectedValue,
            formatMessage,
          );
        } else {
          result.expectedValue = formatMessage(
            AVA_FORMAT_FIELDS[result.name],
            result.expectedValue,
          );
        }
      }

      if (result.actualValue) {
        if (isMultiExp(result.actualValue)) {
          result.actualValue = formatMulti(
            AVA_FORMAT_FIELDS[result.name],
            result.actualValue,
            formatMessage,
          );
        } else {
          result.actualValue = formatMessage(
            AVA_FORMAT_FIELDS[result.name],
            result.actualValue,
          );
        }
      }
    }
  });

  return output;
}
