<template>
  <CModal
    class="ava-invoice-results-details-modal modal-xxl"
    :show.sync="modalShow"
    size="xl"
    centered
    :close-on-backdrop="false"
  >
    <template #header>
      <div class="d-flex w-100">
        <h2>Doc. Code: {{ documentCode }}</h2>
        <MRecordStatusBadge
          :value="recordStatus"
          class="h2"
          style="margin-left: auto; margin-right: 0"
        />
      </div>
    </template>
    <CCard class="document-details-card ava-document-details-card">
      <CCardBody>
        <CRow>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Company Code</small><br />
              <strong class="h4">{{ companyCode }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Customer Code</small><br />
              <strong class="h4">{{ customerCode }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout color="primary">
              <small class="text-muted">Res. Document Type</small><br />
              <strong class="h4">{{ documentType }}</strong>
            </CCallout>
          </CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol col="4">
            <fieldset class="d-inline-flex">
              <legend>General</legend>
              <LabeledValue label="Req. Doc Type">
                {{
                  !!value
                    ? translate('avaDocType', value.documentType)
                    : null || '--'
                }}
              </LabeledValue>
              <LabeledValue label="Document Date" class="ml-2">
                {{ documentDate }}
              </LabeledValue>
              <LabeledValue label="Currency Code" class="ml-2">
                {{ currencyCode }}
              </LabeledValue>
              <LabeledValue label="Business Id. No." class="ml-2">
                {{ businessIdNo || '--' }}
              </LabeledValue>
            </fieldset>
          </CCol>
          <CCol>
            <fieldset class="d-inline-flex">
              <legend>Response Summary</legend>
              <LabeledValue label="Tax Date">
                {{ taxDate || '--' }}
              </LabeledValue>
              <LabeledValue label="Total Amount" class="ml-2">
                {{ totalAmount || '--' }}
              </LabeledValue>
              <LabeledValue label="Total Tax Amount" class="ml-2">
                {{ totalTaxAmount || '--' }}
              </LabeledValue>
              <LabeledValue label="Total Exempt" class="ml-2">
                {{ totalExempt || '--' }}
              </LabeledValue>
            </fieldset>
          </CCol>
        </CRow>

        <CTabs :active-tab.sync="activeTab" class="pt-5">
          <CTab title="Document" class="invoice-details-tab">
            <CCard class="no-border">
              <CCardBody>
                <CRow class="pb-4">
                  <CCol>
                    <CRow>
                      <CCol><h4>Document Level Validations</h4></CCol>
                    </CRow>
                    <CRow v-if="hasDocumentValidations">
                      <CCol
                        v-for="(v, vIdx) in validationResults"
                        :key="`${vIdx}_${v.name}`"
                        col="4"
                      >
                        <AvaValidationResult :value="v" />
                      </CCol>
                    </CRow>
                    <CRow v-else>
                      <CCol>
                        <div class="text-muted h4 mt-2 ml-1">
                          None <small class="ml-1">(see lines)</small>
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <div class="w-100 d-flex">
                      <span class="h4">Document Input Properties</span>
                      <MCollapseToggler :value.sync="showInvoiceInput" />
                    </div>
                    <hr />
                  </CCol>
                </CRow>
                <CCollapse :show="showInvoiceInput">
                  <CRow>
                    <CCol>
                      <CCard>
                        <CCardBody>
                          <CRow v-if="hasInvoiceUserElements" class="pb-4">
                            <CCol v-if="hasInvoiceUserElements" col="6">
                              <fieldset>
                                <legend>User Elements</legend>
                                <div class="user-element-container">
                                  <LabeledValue
                                    v-for="e in invoiceUserElements"
                                    :key="e.key"
                                    :label="e.label"
                                    :value="e.value"
                                  />
                                </div>
                              </fieldset>
                            </CCol>
                          </CRow>
                          <CRow v-if="hasDocumentAddresses" class="pb-4">
                            <CCol>
                              <fieldset>
                                <legend>
                                  Addresses
                                  <small class="text-muted">
                                    ({{ documentAddresses.length }})
                                  </small>
                                </legend>
                                <CRow>
                                  <CCol
                                    v-for="address in documentAddresses"
                                    :key="address.key"
                                    col="6"
                                    md="4"
                                    lg="3"
                                  >
                                    <DocumentAddress
                                      :value="address"
                                      :label="
                                        $t(`addressType.${address.group}`)
                                      "
                                      class="pt-2"
                                    />
                                  </CCol>
                                </CRow>
                              </fieldset>
                            </CCol>
                          </CRow>
                          <CRow
                            v-if="documentAttributes.length > 0"
                            class="pb-4"
                          >
                            <CCol>
                              <fieldset>
                                <legend>All Other Document Attributes</legend>
                                <div
                                  v-if="documentAttributes.length > 0"
                                  class="user-element-container"
                                >
                                  <LabeledValue
                                    v-for="e in documentAttributes"
                                    :key="e.key"
                                    :label="e.label"
                                  >
                                    <MCheckIcon
                                      v-if="e.fieldType === 'BOOLEAN'"
                                      :value="e.value"
                                      show-false
                                    />
                                    <span v-else>
                                      {{ e.value }}
                                    </span>
                                  </LabeledValue>
                                </div>
                                <div v-else class="text-muted h4 mt-2 ml-1">
                                  None
                                </div>
                              </fieldset>
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </CCollapse>
              </CCardBody>
            </CCard>
          </CTab>

          <CTab :title="`Lines (${lineCount})`" class="line-details-tab">
            <CTabs
              :active-tab="activeLineTab"
              class="pt-4"
              variant="pills"
              :vertical="{
                navs: 'col-xs-2 col-md-1',
                content: 'col-xs-10 col-md-11',
              }"
            >
              <CTab
                v-for="(line, index) in documentLines"
                :key="`${index}_${line.lineNumber}_${line.key}`"
                :title="`Line ${line.lineNumber}`"
              >
                <AvaDocumentLineResultTab
                  :line="line"
                  :currency-code="currencyCode"
                  :doc-status="recordStatus"
                />
              </CTab>
            </CTabs>
          </CTab>

          <CTab title="JSON" class="json-tab">
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol col="6" style="position: relative">
                    <h4>Request JSON</h4>
                    <MClipboardButton
                      v-if="!!requestJSON.plain"
                      id="btn-ava-invoice-details-request-copy"
                      style="
                        margin-right: 1rem;
                        position: absolute;
                        top: 5px;
                        right: 25px;
                      "
                      @click="copyTextToClipBoard"
                    />

                    <pre
                      :id="jsonDetailsId"
                      class="code hljs json"
                      v-html="requestJSON.highlight"
                    ></pre>
                  </CCol>
                  <CCol col="6" style="position: relative">
                    <h4>Response JSON</h4>
                    <MClipboardButton
                      v-if="!!responseJSON.plain"
                      id="btn-ava-invoice-details-response-copy"
                      style="
                        margin-right: 1rem;
                        position: absolute;
                        top: 5px;
                        right: 25px;
                      "
                      @click="copyResponseToClipBoard"
                    />

                    <pre
                      :id="jsonResponseDetailsId"
                      class="code hljs json"
                      v-html="responseJSON.highlight"
                    ></pre>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
    <template #footer>
      <CButton
        id="btn-ava-invoice-results-details-close"
        color="primary"
        @click="modalShow = false"
        >Close</CButton
      >
    </template>
  </CModal>
</template>
<script>
import DocumentAddress from '@/components/DocumentAddress';
import AvaDocumentLineResultTab from '@/views/testing/results/ava/_components/AvaDocumentResultsDetailsModal/AvaDocumentLineResultTab.vue';
import { MClipboardButton } from '@/components/Buttons';
import MRecordStatusBadge from '@/components/MRecordStatusBadge';
import AvaValidationResult from '@/views/testing/results/ava/_components/AvaValidationResult';
import {
  isIgnoredField,
  getFieldForProperty,
} from '@/utils/ava/avaFieldUtils.js';
import { titleCase } from '@/utils/helpers';
import hljs from 'highlight.js';
export default {
  name: 'AvaDocumentResultsDetailsModal',
  components: {
    AvaDocumentLineResultTab,
    DocumentAddress,
    MClipboardButton,
    MRecordStatusBadge,
    AvaValidationResult,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInvoiceInput: false,
      activeTab: 0,
      activeLineTab: 0,
      registrations: [],
      businessIdNo: null,
      counterParty: null,
      companyCode: null,
      documentCode: null,
      documentType: null,
      documentDate: null,
      currencyCode: null,
      customerCode: null,
      invoiceUserElements: [],
      hasOtherElements: false,
      documentAddresses: [],
      documentAttributes: [],
      documentLines: [],
      requestJSON: '',
      responseJSON: '',
      recordStatus: null,
      totalAmount: 0,
      totalTaxAmount: 0,
      totalExempt: 0,
      taxDate: null,
      responseStatus: null,
      validationResults: [],
    };
  },
  computed: {
    jsonDetailsId() {
      return `ava-json-result-details_${this.value.id}`;
    },
    jsonResponseDetailsId() {
      return `ava-json-result-response-details_${this.value.id}`;
    },
    modalShow: {
      get() {
        return this.show === true;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
    hasDocumentAddresses() {
      return this.documentAddresses.length > 0;
    },
    hasDocumentValidations() {
      return this.validationResults.length > 0;
    },
    lineCount() {
      return this.documentLines?.length || 0;
    },

    hasInvoiceUserElements() {
      return this.invoiceUserElements && this.invoiceUserElements.length > 0;
    },
  },
  mounted() {
    const doc = this.value._value || this.value;
    this.businessIdNo =
      doc.documentProperties['DOCUMENT.BUSINESS_IDENTIFICATION_NO'];
    this.documentCode = doc.documentCode;
    this.companyCode = doc.companyCode;
    this.customerCode = doc.customerCode;
    this.documentType = this.translate(
      'avaDocType',
      doc.documentProperties['DOCUMENT.RESPONSE.TYPE'] || doc.documentType,
    );
    this.currencyCode = doc.currencyCode;
    this.documentDate = doc.documentDate;
    this.recordStatus = doc.recordStatus;

    if (doc.requestJson) {
      const requestHighlight = hljs.highlight(doc.requestJson, {
        language: 'json',
        ignoreIllegals: true,
      });
      this.requestJSON = {
        plain: doc.requestJson,
        highlight: requestHighlight.value,
      };
    }

    if (doc.responseJson) {
      const responseHighlight = hljs.highlight(doc.responseJson, {
        language: 'json',
        ignoreIllegals: true,
      });

      this.responseJSON = {
        plain: doc.responseJson,
        highlight: responseHighlight.value,
      };
    }
    if (doc.totalTaxAmount > 0) {
      this.totalTaxAmount = this.$format.currency(
        doc.currencyCode,
        doc.totalTaxAmount,
      );
    }

    this.responseStatus = doc.documentProperties['DOCUMENT.RESPONSE.STATUS'];
    this.totalAmount = this.getFormattedCurrency(
      doc.currencyCode,
      doc.documentProperties['DOCUMENT.RESPONSE.TOTAL_AMOUNT'],
    );
    this.totalExempt = this.getFormattedCurrency(
      doc.currencyCode,
      doc.documentProperties['DOCUMENT.RESPONSE.TOTAL_EXEMPT'],
    );
    this.taxDate = doc.documentProperties['DOCUMENT.RESPONSE.TAX_DATE'];

    this.documentAttributes = Object.keys(doc.documentProperties)
      .filter(
        (key) => !isIgnoredField(key) && !key.startsWith('DOCUMENT.RESPONSE'),
      )
      .map((key) => {
        const field = getFieldForProperty(key);
        const value = doc.documentProperties[key];
        return {
          label: titleCase(
            field?.label || key.replace('DOCUMENT.', '').replace('_', ' '),
            true,
          ),
          value: value,
        };
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.documentAddresses = Object.keys(doc.addresses || {})
      .map((key) => {
        const group = key.replace('DOCUMENT.', '');
        return {
          ...doc.addresses[key],
          group,
          label: this.$t(`addressType.${group}`),
        };
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.documentUserElements = Object.keys(doc.userDefinedFields || {})
      .map((field) => {
        return {
          label: field,
          value: doc.userDefinedFields[field],
        };
      })
      .sort((a, b) => {
        return ('' + a.label).localeCompare(b.label);
      });

    this.validationResults = (doc.validationResults?.document || []).sort(
      (a, b) => {
        return ('' + a.name).localeCompare(b.name);
      },
    );

    this.documentLines = (doc.lines || []).map((line, idx) => {
      return Object.freeze({
        ...line,
        key: `ava_line_${doc.id}_${idx}_${line.lineNumber}`,
        index: idx,
      });
    });
  },
  methods: {
    translate(key, value) {
      if (!value) {
        return null;
      } else {
        let v = `${key}.${value}`;
        const translated = this.$t(v);
        if (translated === v) {
          return value;
        } else {
          return translated;
        }
      }
    },
    getFormattedCurrency(currencyCode, value) {
      if (!value || value <= 0) {
        return null;
      }

      return this.$format.currency(currencyCode, value);
    },
    copyTextToClipBoard() {
      if (this.requestJSON && this.requestJSON.plain) {
        const copyText = document.createElement('textarea');
        const parent = document.getElementById(this.jsonDetailsId);
        copyText.value = this.requestJSON.plain;
        copyText.setAttribute('readonly', 'true');
        copyText.style = { visibility: 'hidden' };
        parent.appendChild(copyText);
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
        parent.removeChild(copyText);
      }
    },
    copyResponseToClipBoard() {
      if (this.responseJSON && this.responseJSON.plain) {
        const copyText = document.createElement('textarea');
        const parent = document.getElementById(this.jsonResponseDetailsId);
        copyText.value = this.responseJSON.plain;
        copyText.setAttribute('readonly', 'true');
        copyText.style = { visibility: 'hidden' };
        parent.appendChild(copyText);
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
        parent.removeChild(copyText);
      }
    },
  },
};
</script>
