import hljs from 'highlight.js/lib/core';
import {
  SBX_INVOICE_FIELDS_MAP,
  SBX_INVOICE_ADDRESS_FIELD_KEYS,
  SBX_LINE_ADDRESS_FIELD_KEYS,
  SBX_LINE_FIELDS_MAP,
  // parseCustomAttributes,
} from '@/utils/sbxFieldUtils';

export const IGNORED_ATTRIBUTES = new Set([
  ...SBX_INVOICE_ADDRESS_FIELD_KEYS,
  ...SBX_LINE_ADDRESS_FIELD_KEYS,
]);

IGNORED_ATTRIBUTES.add('INVOICE.COMPANY_NAME');
IGNORED_ATTRIBUTES.add('INVOICE.INVOICE_NUMBER');
IGNORED_ATTRIBUTES.add('INVOICE.INVOICE_DATE');
IGNORED_ATTRIBUTES.add('INVOICE.EXTERNAL_COMPANY_ID');
IGNORED_ATTRIBUTES.add('INVOICE.CURRENCY_CODE');
IGNORED_ATTRIBUTES.add('INVOICE.TRANSACTION_TYPE');
IGNORED_ATTRIBUTES.add('INVOICE.COMPANY_ROLE');
IGNORED_ATTRIBUTES.add('INVOICE.REGISTRATIONS.BUYER');
IGNORED_ATTRIBUTES.add('INVOICE.REGISTRATIONS.SELLER');
IGNORED_ATTRIBUTES.add('INVOICE.REGISTRATIONS.MIDDLEMAN');
IGNORED_ATTRIBUTES.add('INVOICE.CUSTOMER_NUMBER');
IGNORED_ATTRIBUTES.add('INVOICE.CUSTOMER_NAME');
IGNORED_ATTRIBUTES.add('INVOICE.VENDOR_NUMBER');
IGNORED_ATTRIBUTES.add('INVOICE.VENDOR_NAME');
IGNORED_ATTRIBUTES.add('LINE.LINE_NUMBER');
IGNORED_ATTRIBUTES.add('LINE.COMPANY_ROLE');
IGNORED_ATTRIBUTES.add('LINE.REGISTRATIONS.BUYER');
IGNORED_ATTRIBUTES.add('LINE.REGISTRATIONS.SELLER');
IGNORED_ATTRIBUTES.add('LINE.REGISTRATIONS.MIDDLEMAN');
IGNORED_ATTRIBUTES.add('LINE.CUSTOMER_NUMBER');
IGNORED_ATTRIBUTES.add('LINE.CUSTOMER_NAME');
IGNORED_ATTRIBUTES.add('LINE.VENDOR_NUMBER');
IGNORED_ATTRIBUTES.add('LINE.VENDOR_NAME');
IGNORED_ATTRIBUTES.add('LINE.PRODUCT_CODE');
IGNORED_ATTRIBUTES.add('LINE.COMMODITY_CODE');
IGNORED_ATTRIBUTES.add('LINE.QUANTITY');
IGNORED_ATTRIBUTES.add('LINE.UOM');
IGNORED_ATTRIBUTES.add('LINE.GROSS_AMOUNT');
IGNORED_ATTRIBUTES.add('INVOICE.VCT_STATUS');
IGNORED_ATTRIBUTES.add('INVOICE.VCT_RESPONSE_CODE');

function getRegistrations(value, predicate = 'INVOICE') {
  return {
    buyer: value[`${predicate}.REGISTRATIONS.BUYER`],
    seller: value[`${predicate}.REGISTRATIONS.SELLER`],
    middleman: value[`${predicate}.REGISTRATIONS.MIDDLEMAN`],
  };
}

function getLineCounterParty(value, registrations, companyRole) {
  const retval = {
    label: 'Customer',
    name: value.customerName || value.lineProperties['LINE.CUSTOMER_NAME'],
    number: value.customerNumber || value['LINE.CUSTOMER_NUMBER'],
    registration: registrations?.buyer,
  };

  if (companyRole === 'BUYER') {
    retval.label = 'Vendor';
    retval.name = value.vendorName || value['LINE.VENDOR_NAME'];
    retval.number = value.vendorNumber || value['LINE.VENDOR_NUMBER'];
    retval.registration = registrations?.seller;
  }

  if (retval.name || retval.number) {
    return retval;
  }

  return null;
}

function getCounterParty(value, registrations) {
  const companyRole = (
    value._details?.companyRole || value['INVOICE.COMPANY_ROLE']
  )?.toUpperCase();
  const retval = {
    label: 'Customer',
    name: value._details?.customerName || value['INVOICE.CUSTOMER_NAME'],
    number: value._details?.customerNumber || value['INVOICE.CUSTOMER_NUMBER'],
    registration: registrations?.buyer,
  };

  if (companyRole === 'BUYER' || companyRole === 'B') {
    retval.label = 'Vendor';
    retval.name = value._details?.vendorName || value['INVOICE.VENDOR_NAME'];
    retval.number =
      value._details?.vendorNumber || value['INVOICE.VENDOR_NUMBER'];
    retval.registration = registrations?.seller;
  }

  if (retval.name || retval.number) {
    return retval;
  }

  return null;
}

function isBoolean(value) {
  if (value === true || value === false) {
    return true;
  }

  const strValue = `${value}`.toUpperCase().trim();
  return strValue === 'TRUE' || strValue === 'FALSE';
}

function parseLineAttributes(value) {
  const attrs = Object.keys(value)
    .filter((lineKey) => {
      const key = lineKey.toUpperCase();
      if (
        key.startsWith('INVOICE.') ||
        key.startsWith('EXPECTED.INVOICE') ||
        key.startsWith('RESULTS.')
      ) {
        return false;
      }

      if (IGNORED_ATTRIBUTES.has(key)) {
        return false;
      }

      if (
        key.includes('USER_ELEMENT') ||
        key.includes('EXPECTED') ||
        key.includes('ACTUAL.') ||
        key.includes('RESULTS.')
      ) {
        return false;
      }

      if (key.startsWith('LINE.') || key.startsWith('EXPECTED.')) {
        let isAddressField = false;
        SBX_LINE_ADDRESS_FIELD_KEYS.forEach((k) => {
          if (key.includes(k)) {
            isAddressField = true;
            return false;
          }
        });

        return !isAddressField;
      }

      return false;
    })
    .map((key) => {
      let fieldValue = value[key];
      const field = SBX_LINE_FIELDS_MAP[key];
      const label =
        field?.label || key.replace('LINE.', '').replaceAll('_', ' ');
      let fieldType = field?.fieldType || null;

      if (fieldType === 'BOOLEAN' || isBoolean(fieldValue)) {
        fieldType = 'BOOLEAN';
        fieldValue =
          fieldValue === true ||
          `${fieldValue}`.toUpperCase().trim() === 'TRUE';
      }

      return {
        key,
        label: label.replace('Line ', ''),
        fieldType,
        value: fieldValue,
        fieldPath: key,
      };
    });

  return attrs;
}

function parseDocumentAttributes(value) {
  return Object.keys(value)
    .filter((invoiceKey) => {
      const key = invoiceKey.toUpperCase();
      if (
        !key.startsWith('INVOICE.') ||
        key.startsWith('EXPECTED.INVOICE') ||
        key.startsWith('RESULTS' || key.startsWith('ACTUAL'))
      ) {
        return false;
      }

      if (IGNORED_ATTRIBUTES.has(key)) {
        return false;
      }

      let isAddressField = false;
      SBX_INVOICE_ADDRESS_FIELD_KEYS.forEach((k) => {
        if (key.includes(k)) {
          isAddressField = true;
          return false;
        }
      });

      if (isAddressField) {
        return false;
      }

      if (key.includes('USER_ELEMENT')) {
        return false;
      }

      if (key.includes('RESULTS.') || key.includes('ACTUAL.')) {
        return false;
      }

      return true;
    })
    .map((key) => {
      let fieldValue = value[key];
      const field = SBX_INVOICE_FIELDS_MAP[key];
      const label =
        field?.label || key.replace('INVOICE.', '').replaceAll('_', ' ');

      let fieldType = field?.fieldType || null;

      if (fieldType === 'BOOLEAN' || isBoolean(fieldValue)) {
        fieldType = 'BOOLEAN';
        fieldValue =
          fieldValue === true ||
          `${fieldValue}`.toUpperCase().trim() === 'TRUE';
      }

      return {
        key,
        label,
        fieldType,
        value: fieldValue,
        fieldPath: key,
      };
    });
}

function parseLines(companyRole, currencyCode, lines) {
  const result = (lines || []).map((original) => {
    const line = {
      ...original,
    };

    line.currencyCode = currencyCode;
    line.registrations = getRegistrations(original.lineProperties, 'LINE');
    line.hasRegistrations = !!(
      line.registrations.buyer ||
      line.registrations.middleman ||
      line.registrations.seller
    );

    line.counterParty = getLineCounterParty(
      line,
      line.registrations,
      companyRole,
    );

    line.hasCustomAttributes = line.customAttributes?.length > 0;
    line.lineAttributes = parseLineAttributes(line.lineProperties);
    line.hasLineAttributes = line.lineAttributes.length > 0;
    line.hasLineValidationResults = line.validationResults.length > 0;

    if (!line.products) {
      line.products = [];
      if (line.lineProperties['LINE.PRODUCT_CODE']) {
        line.products.push({
          type: 'PRODUCT_CODE',
          code: line.lineProperties['LINE.PRODUCT_CODE'],
        });
      }

      if (line.lineProperties['LINE.COMMODITY_CODE']) {
        line.products.push({
          type: 'COMMODITY_CODE',
          code: line.lineProperties['LINE.COMMODITY_CODE'],
        });
      }
    }

    return Object.freeze(line);
  });

  return result;
}

function stripXMLNS(xml) {
  return xml.replace(/(\s)?xmlns:.*=".*">/, '>');
}

export function parseValue(value) {
  const registrations = getRegistrations(value);
  const counterParty = getCounterParty(value, registrations);
  const companyRole = (
    value._details?.companyRole || value['INVOICE.COMPANY_ROLE']
  )?.toUpperCase();
  const currencyCode =
    (
      value._details?.currencyCode || value['INVOICE.CURRENCY_CODE']
    )?.toUpperCase() || 'USD';
  return {
    registrations,
    counterParty,
    invoiceNumber:
      value._details?.invoiceNumber || value['INVOICE.INVOICE_NUMBER'],
    externalCompanyId:
      value._details?.externalCompanyId || value['INVOICE.EXTERNAL_COMPANY_ID'],
    companyRole,
    companyName: value._details?.companyName || value['INVOICE.COMPANY_NAME'],
    transactionType: value['INVOICE.TRANSACTION_TYPE'],
    invoiceDate:
      value._details?.invoiceDate ||
      value['INVOICE.INVOICE_DATE'] ||
      'Variable',
    titleTransfer: value['INVOICE.POINT_OF_TITLE_TRANSFER'],
    currencyCode,
    invoiceUserElements: value._details.customAttributes || [],
    invoiceAddresses: value.addresses || value._details.addresses || [],
    documentAttributes: parseDocumentAttributes(value),
    documentLines: parseLines(
      companyRole,
      currencyCode,
      value._details.lines || [],
    ),
    documentXML: stripXMLNS(value._details.documentXML),
    responseXML: stripXMLNS(value._details.responseXML),
    recordStatus: value.recordStatus,
    totalTaxAmount: value._details.totalTaxAmount,
    taxSummary: value._details.taxSummary,
    validationResults: value._details.validationResults,
    lineCount: value._details?.lineCount || value.lineCount,
  };
}

export function highlightXML(xmlText) {
  const result = hljs.highlight(xmlText, {
    language: 'xml',
    ignoreIllegals: true,
  });
  return result.value;
}
