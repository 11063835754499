<template>
  <div>
    <CBadge :color="badgeColor">{{ badgeText }} </CBadge>
  </div>
</template>
<script>
export default {
  name: 'MRecordStatusBadge',
  props: {
    value: {
      type: String,
      required: false,
      default: 'ERROR',
    },
  },
  computed: {
    badgeColor() {
      switch (this.value?.toUpperCase()) {
        case 'VALID':
          return 'success';
        case 'INVALID':
          return 'danger';
        default:
          return 'warning';
      }
    },
    badgeText() {
      switch (this.value?.toUpperCase()) {
        case 'VALID':
          return 'PASS';
        case 'INVALID':
          return 'FAIL';
        default:
          return 'ERROR';
      }
    },
  },
};
</script>
