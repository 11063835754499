import { getFieldLabel } from '@/utils/vtxFieldUtils';
import VtxCounterParty from '@/models/VtxCounterParty';
import hljs from 'highlight.js/lib/core';

const IGNORED_DOC_ATTRS = new Set([
  'DOC_DATE',
  'DOC_ADMIN_DEST',
  'DOC_ADMIN_ORIGIN',
  'DOC_CURRENCY_CODE',
  'DOC_CUSTOMER_CODE',
  'DOC_CUSTOMER_TAX_REG_NUM',
  'DOC_CUSTOMER_CLASS_CODE',
  'DOC_DEST',
  'DOC_IS_BUSINESS',
  'DOC_NUM',
  'DOC_PHYS_ORIGIN',
  'DOC_TRANS_TYPE',
  'COMPANY',
  'DIVISION',
  'DEPARTMENT',
  'DOC_VENDOR_CODE',
  'DOC_VENDOR_TAX_REG_NUM',
  'DOC_VENDOR_CLASS_CODE',
]);

const IGNORED_LINE_ATTRS = new Set([
  'LINE_NUM',
  'LINE_ADMIN_DEST',
  'LINE_ADMIN_ORIGIN',
  'LINE_COMMODITY_CODE',
  'LINE_EXTENDED_PRICE',
  'LINE_DEST',
  // 'LINE_MATERIAL_CODE',
  'LINE_PHYS_ORIGIN',
  'LINE_PRODUCT',
  'LINE_PRODUCT_CLASS',
  'LINE_QUANTITY',
  'LINE_QUANTITY_UOM',
  // 'LINE_USAGE',
  // 'LINE_USAGE_CLASS',
]);

export function parseTransactionTypeValue(value) {
  const transactionType = (
    value?.transactionType ||
    value?.DOC_TRANS_TYPE ||
    value
  )
    ?.toUpperCase()
    ?.trim();
  switch (transactionType) {
    case 'SALE':
      return 'SALE';
    case 'PURCHASE':
      return 'PURCHASE';
    default:
      return null;
  }
}

export function parseTaxpayerRole(value) {
  switch (parseTransactionTypeValue(value)) {
    case 'SALE':
      return 'SELLER';
    case 'PURCHASE':
      return 'BUYER';
    default:
      return null;
  }
}

export function parseDocCounterParty(value) {
  switch (parseTransactionTypeValue(value)) {
    case 'SALE':
      return new VtxCounterParty('CUSTOMER', {
        code: value.DOC_CUSTOMER_CODE,
        registration: value.DOC_CUSTOMER_TAX_REG_NUM,
        classCode: value.DOC_CUSTOMER_CLASS_CODE,
        isBusiness: value.DOC_IS_BUSINESS,
        empty: !value.DOC_CUSTOMER_CODE && !value.DOC_CUSTOMER_TAX_REG_NUM,
      });
    case 'PURCHASE':
      return new VtxCounterParty('VENDOR', {
        code: value.DOC_VENDOR_CODE,
        registration: value.DOC_VENDOR_TAX_REG_NUM,
        classCode: value.DOC_VENDOR_CLASS_CODE,
        isBusiness: value.DOC_IS_BUSINESS,
        empty: !value.DOC_VENDOR_CODE && !value.DOC_VENDOR_TAX_REG_NUM,
      });
  }

  return new VtxCounterParty();
}

export function parseDocExpectations(value) {
  const retval = {
    hasInvoiceExpectations: false,
    expectedDocTaxability: null,
  };

  if (value['EXPECTED_DOC_ZERO-NONZERO']) {
    retval.hasInvoiceExpectations = true;
    retval.expectedDocTaxability = value['EXPECTED_DOC_ZERO-NONZERO'];
  }

  return retval;
}

export function isAddressAttribute(key) {
  return (
    key.includes('PHYS_ORIGIN_') ||
    key.includes('DEST_') ||
    key.includes('ADMIN_DEST_') ||
    key.includes('ADMIN_PHYS_ORIGIN_')
  );
}

export function parseLineAttributes(value) {
  return Object.keys(value || {})
    .filter((key) => {
      return (
        key.startsWith('LINE_') &&
        !key.startsWith('LINE_FLEX_FIELD_') &&
        !IGNORED_LINE_ATTRS.has(key) &&
        !isAddressAttribute(key)
      );
    })
    .map((key) => {
      return {
        key,
        label: getFieldLabel(key),
        value: value[key],
      };
    });
}

export function parseLineAddresses(addresses) {
  return addresses.map((a) => {
    let addressType = a.addressType;
    switch (a.addressType) {
      case 'DESTINATION':
        addressType = 'LINE_DEST';
        break;
      case 'ADMINISTRATIVE_DESTINATION':
        addressType = 'LINE_ADMIN_DEST';
        break;
      case 'ADMINISTRATIVE_ORIGIN':
        addressType = 'LINE_ADMIN_ORIGIN';
        break;
      case 'PHYSICAL_ORIGIN':
        addressType = 'LINE_PHYS_ORIGIN';
        break;
    }

    return {
      ...a,
      addressType,
    };
  });
}

export function parseDocAttributes(value) {
  return Object.keys(value)
    .filter((key) => {
      return (
        key.startsWith('DOC_') &&
        !IGNORED_DOC_ATTRS.has(key) &&
        !isAddressAttribute(key)
      );
    })
    .map((key) => {
      return {
        key,
        label: getFieldLabel(key),
        value: value[key],
      };
    });
}

export function parseDocRegistrations(value) {
  let registrations = [];
  switch (parseTransactionTypeValue(value)) {
    case 'SALE':
      if (value.DOC_VENDOR_TAX_REG_NUM) {
        registrations.push({
          type: 'VENDOR',
          registration: value.DOC_VENDOR_TAX_REG_NUM,
        });
      }
      break;
    case 'PURCHASE':
      if (value.DOC_CUSTOMER_TAX_REG_NUM) {
        registrations.push({
          type: 'CUSTOMER',
          registration: value.DOC_CUSTOMER_TAX_REG_NUM,
        });
      }
      break;
  }
  return registrations;
}

export function highlightXML(xmlText) {
  const result = hljs.highlight(xmlText, {
    language: 'xml',
    ignoreIllegals: true,
  });
  return result.value;
}

export function parseDocumentXML(xml) {
  return {
    plain: xml,
    highlight: highlightXML(xml),
  };
}

export function parseResponseXML(xml) {
  return {
    plain: xml,
    highlight: highlightXML(xml),
  };
}

export function parseFlexFieldLabel(fieldId) {
  let label = `${fieldId}`;
  if (label.length === 1) {
    return `0${label}`;
  } else {
    return label;
  }
}
